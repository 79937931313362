import { BrowserRouter } from "react-router-dom";
import { NativeRouter } from "react-router-native";
import { isWeb } from "src/Themes/variables";
import { NativeRouterType } from "src/types";

const Router = isWeb ? BrowserRouter : NativeRouter;

const AppRouter = Router as NativeRouterType;

export default AppRouter;
