import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from "axios";
import { BASE_URL } from "src/Config";
import I18n from "src/I18n";
import {
  transformCargoTypeApiResponse,
  transformCommute,
  transformCommutes,
  transformCommuteWithActualVehiclesRoutes,
  transformCompany,
  transformGetApiVersionResponse,
  transformPartialCommute,
  transformUser,
} from "src/Services/transformResponses";
import Subscriber from "src/Subscriber";
import {
  ApiVersionData,
  IChangeDeliveryStatus,
  ICommute,
  ICompany,
  IGetCargoTypesResponse,
  IGetCommuteApiResponse,
  IGetCommutesApiResponse,
  IRawCommuteData,
  IRawCommuteDataWithActualVehiclesRoutes,
  IRawCompanyApiResponse,
  IRawNestedUserApiResponse,
  IUser,
  IVehicle,
} from "src/types";

type AxiosRequestParams = AxiosRequestConfig & ApiRequestParams;

interface IAxios {
  get(url: string, p?: { params?: AxiosRequestParams }): AxiosPromise;
  post(url: string, p?: AxiosRequestParams): AxiosPromise;
  put(url: string, p: AxiosRequestParams): AxiosPromise;
  delete(url: string, p?: AxiosRequestParams): AxiosPromise;
}

enum RIDE_TYPES {
  commute = "Commute",
  shadow = "Shadow",
  request = "Request",
}

export type GetCommutesParamsType = {
  date?: string;
  modelMethods?: string[];
  page?: number;
  limit?: number;
  startDate?: Date;
  endDate?: Date;
  vehicleType?: string;
  onlyByThisDriver?: number;
  sortKey?: string;
  sortDir?: string;
  vehicleTypeId?: string;
};

export type GetVehicleParamsType = {
  authorizeByCommute?: string;
};

export type GetCommuteParamsType = { modelMethods?: string[] };

export type LoginParamsType = { email: string; password: string } | { password: string; confirmPassword: string };

export type ApiGetParams = GetCommutesParamsType | GetVehicleParamsType | GetCommuteParamsType;

type CommuteDate = { date: string };
export type ApiPostParams =
  | IChangeDeliveryStatus
  | Partial<IUser>
  | Partial<ICommute & CommuteDate>
  | Partial<IVehicle>
  | Partial<ICompany>
  | LoginParamsType;

export type ApiPutParams = ApiPostParams;

export type ApiRequestParams = ApiGetParams | ApiPostParams | ApiPutParams;

export type ApiErrorData = { status?: number; message?: string };
type ApiErrorResponse = { response: AxiosResponse<ApiErrorData> };

class Api {
  private axios: IAxios;
  private elementId?: string;
  private params?: ApiRequestParams;
  constructor(id?: string, params?: ApiRequestParams) {
    this.axios = axios.create({
      baseURL: BASE_URL,
      timeout: 60 * 1000,
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    this.elementId = id;
    this.params = params;
  }

  public login = async () => {
    const res: AxiosResponse<{ data: IRawNestedUserApiResponse }> = await this.post("login", this.params);
    const user = transformUser(res.data.data);
    return user;
  };

  public getCommutes = async () => {
    const dayInMs = 1000 * 60 * 60 * 24 * 3;
    const reqData = this.params as GetCommutesParamsType;
    const onlyByThisDriver = reqData.onlyByThisDriver || 0;
    const limit = reqData.limit || 10;
    const page = reqData.page || 0;
    const startDate = reqData.startDate || new Date(Date.now() - dayInMs);
    const endDate = reqData.endDate || new Date("3000-01-01");
    const vehicleTypeId = reqData.vehicleType || undefined;
    const sortKey = reqData.sortKey;
    const sortDir = reqData.sortDir;
    const res: AxiosResponse<IGetCommutesApiResponse> = await this.get(`/rides?type=${RIDE_TYPES.commute}`, {
      date: [startDate, endDate].join(","),
      limit,
      page,
      vehicleTypeId,
      onlyByThisDriver,
      sortKey: sortKey,
      sortDir: sortDir,
      modelMethods: [],
    });
    return transformCommutes(res.data);
  };

  public getCommute = async () => {
    const res: AxiosResponse<{
      data: IRawCommuteData;
    }> = await this.get(`/rides/${this.elementId}`, this.params);
    return transformCommute(res.data.data);
  };

  public getCommuteWithActualVehiclesRoutes = async () => {
    const params = {
      modelMethods: ["setActualVehiclesRoutes"],
    };

    const res: AxiosResponse<{
      data: IRawCommuteDataWithActualVehiclesRoutes;
    }> = await this.get(`/rides/${this.elementId}`, params);

    return transformCommuteWithActualVehiclesRoutes(res.data.data);
  };

  public getCargoTypes = async () => {
    const res: AxiosResponse<IGetCargoTypesResponse> = await this.get("cargo-types");
    return transformCargoTypeApiResponse(res.data);
  };

  public deleteRide = async () => {
    await this.delete(`rides/${this.elementId}`);
  };

  public getUser = async () => {
    const res: AxiosResponse<{ data: IRawNestedUserApiResponse }> = await this.get(`/users/${this.elementId}`);
    return transformUser(res.data.data);
  };

  public getMajorApiVersion = async () => {
    const response: AxiosResponse<ApiVersionData> = await this.get("health");
    const version = transformGetApiVersionResponse(response.data);
    return version;
  };

  public getVehicle = async () => {
    const response: AxiosResponse<{ data: IVehicle }> = await this.get(`vehicles/${this.elementId}`, this.params);
    return response.data.data;
  };

  public editUser = async () => {
    const params = this.params as Partial<IUser>;
    const res: AxiosResponse<{ data: IRawNestedUserApiResponse }> = await this.put(`users/${this.elementId}`, params);
    const user = transformUser(res.data.data);
    return user;
  };

  public forgotPassword = async () => {
    await this.post("forgot-password", this.params);
  };

  public resetPassword = async () => {
    await this.post("reset-password", this.params);
  };

  public logout = async () => {
    await this.post("logout");
    return {};
  };

  public getCompany = async () => {
    const response: AxiosResponse<IRawCompanyApiResponse> = await this.get(`/companies/${this.elementId}`);
    return transformCompany(response.data);
  };

  public editCommute = async () => {
    const dataObj = {
      ...this.params,
      type: "Commute" as "Commute",
    };
    const response: { data: { data: IRawCommuteData } } = await this.put(`rides/${this.elementId}`, dataObj);
    return transformPartialCommute(response.data.data);
  };

  public setDeliveryStatus = async () => {
    const params = this.params as IChangeDeliveryStatus;
    const res: AxiosResponse<IGetCommuteApiResponse> = await this.put(
      `rides/${this.elementId}/set-delivery-status`,
      params,
    );

    return transformPartialCommute(res.data.data);
  };

  private get = async (url: string, params?: ApiRequestParams) => {
    try {
      const response = await this.axios.get(url, { params });
      return response;
    } catch (error) {
      const apiErrorResponse = error as ApiErrorResponse;
      throw this.handleError(apiErrorResponse);
    }
  };
  private put = async (url: string, data: ApiRequestParams) => {
    try {
      const response = await this.axios.put(url, data);
      return response;
    } catch (error) {
      const apiErrorResponse = error as ApiErrorResponse;
      throw this.handleError(apiErrorResponse);
    }
  };

  private post = async (url: string, data?: ApiRequestParams) => {
    try {
      const response = await this.axios.post(url, data);
      return response;
    } catch (error) {
      const apiErrorResponse = error as ApiErrorResponse;
      throw this.handleError(apiErrorResponse);
    }
  };

  private delete = async (url: string) => {
    try {
      const response = await this.axios.delete(url);
      return response;
    } catch (error) {
      const apiErrorResponse = error as ApiErrorResponse;
      throw this.handleError(apiErrorResponse);
    }
  };

  private handleError = (apiError: ApiErrorResponse) => {
    const { data } = apiError?.response;
    this.showNotification(data);
    throw data;
  };

  private showNotification = (error?: { message?: string }) => {
    if (error && error instanceof Object && error.message) {
      Subscriber.showNotification({
        text: error.message || I18n.t("toast|text|error"),
      });
    }
  };
}

const AppApi = new Api();
export type ApiRequestNames = keyof typeof AppApi;

export { Api };
