import * as React from "react";
import { StyleSheet, Text, ViewStyle } from "react-native";
import { TransparentButton } from "react-native-web-uikit";
import fonts from "src/Themes/fonts";
import { borderRadius, size } from "src/Themes/variables";

interface IProps {
  notificationStyles: {
    color: string;
    textColor: string;
    containerStyles?: ViewStyle;
  };
  text: string;
  hideNotification(): void;
}

const FormNotification = (props: IProps) => {
  return (
    <TransparentButton
      onPress={props.hideNotification}
      style={[styles.notification, { backgroundColor: props.notificationStyles.color }]}>
      <Text style={[styles.toastText, { color: props.notificationStyles.textColor }]}>{props.text}</Text>
    </TransparentButton>
  );
};

const styles = StyleSheet.create({
  notification: {
    width: "100%",
    paddingVertical: size.l,
    paddingHorizontal: size.m,
    borderRadius,
    justifyContent: "flex-start",
  },
  toastText: fonts.style.BodyRegular,
});

export default FormNotification;
