
import * as React from "react";
import { Path, Svg } from "react-native-svg";

export default () => {
  return (
    <Svg width="18" height="22" viewBox="0 0 18 22">
        <Path fill="#FF1744" d="M7.01 20.01C7.01 21.11 7.9 22 9 22s1.99-.89 1.99-1.99H7.01zM9 5c2.76 0 5 2.24 5 5v7H4v-7c0-2.76 2.24-5 5-5zM9 .5c-.83 0-1.5.67-1.5 1.5v1.17C4.36 3.85 2 6.65 2 10v6l-2 2v1h18v-1l-2-2v-6c0-3.35-2.36-6.15-5.5-6.83V2c0-.83-.67-1.5-1.5-1.5zM8 7h2v4H8V7zm0 6h2v2H8v-2z"/>
    </Svg>
  );
};
