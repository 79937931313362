import { IconOptions } from "leaflet";
import { PTV, REGION } from "src/Config";

const PTV_VERSION = PTV?.PTV_VERSION! as "v1" | "v2";
const PTV_API_KEY = PTV?.PTV_API_KEY!;
const PTV_ENV = PTV?.PTV_ENV!;

const profile = "silkysand";
const attribution = "<a href='http://www.ptvgroup.com'>PTV</a>";
const token = PTV_API_KEY;
const isTestPtv = PTV_ENV === "test";
const subdomains = "1234";
// https://github.com/ptv-logistics/xserverjs/tree/master/boilerplate/xmap-1
export const PTV_V1 = {
  tile: {
    url: isTestPtv
      ? "https://api{s}-test.cloud.ptvgroup.com/WMS/GetTile/{profile}/{x}/{y}/{z}.png"
      : "https://api{s}.cloud.ptvgroup.com/WMS/GetTile/{profile}/{x}/{y}/{z}.png",
    attribution,
    subdomains,
    maxZoom: 19,
    profile: ["xmap", profile, "bg"].join("-"),
  },
  wmsTile: {
    url: isTestPtv
      ? "https://api-test.cloud.ptvgroup.com/WMS/WMS?xtok={token}"
      : "https://api.cloud.ptvgroup.com/WMS/WMS?xtok={token}",
    attribution,
    token,
    maxZoom: 19,
    layers: ["xmap", profile, "fg"].join("-"),
    format: "image/png",
    transparent: true,
    zIndex: 3,
  },
};

// https://github.com/ptv-logistics/xserverjs/tree/master/boilerplate
export const PTV_V2 = {
  tile: {
    url: isTestPtv
      ? "https://s0{s}-xserver2-europe-test.cloud.ptvgroup.com/services/rest/XMap/tile/{z}/{x}/{y}" +
        "?storedProfile={profile}&xtok={token}"
      : "https://s0{s}-xserver2-europe.cloud.ptvgroup.com/services/rest/XMap/tile/{z}/{x}/{y}" +
        "?storedProfile={profile}&xtok={token}",
    token,
    profile,
    subdomains,
    attribution,
    maxZoom: 22,
  },
  wmsTile: undefined,
};

export const OMS = {
  tile: {
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution: "&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors",
  },
};

const isEuropeanRegion: boolean = REGION === "Europe";
const ptvConfigs = {
  v1: PTV_V1,
  v2: PTV_V2,
};

export const MAP_TILES_CONFIG = ptvConfigs[PTV_VERSION];

export const MAP_DEFAULT_BOUNDS = isEuropeanRegion
  ? [
      [40, -7],
      [50, 40],
    ]
  : [
      [10, 10],
      [40, 60],
    ];

export const markerOptions: { [x: string]: IconOptions } = {
  destination: {
    iconUrl: "/mapMarkers/route-destination.svg",
    iconSize: [25, 50],
    iconAnchor: [12, 40],
    popupAnchor: [0, -20],
  },
  origin: {
    iconUrl: "/mapMarkers/route-origin.svg",
    iconSize: [80, 80],
    popupAnchor: [0, -10],
  },
  vehicle: {
    iconUrl: "/mapMarkers/vehicle-marker.svg",
    iconSize: [50, 50],
  },
  point: {
    iconUrl: "/mapMarkers/route-destination.svg",
    iconSize: [25, 50],
    iconAnchor: [12, 40],
    popupAnchor: [0, -20],
  },
};

export type MarkerType = "origin" | "destination" | "match" | "vehicle" | "point";
