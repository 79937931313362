import * as React from "react";
import { StyleSheet, Text, View } from "react-native";
import { CroppedThumbnail } from "react-native-web-uikit";
import StoreLoader, { IResponse } from "src/StoreLoader";
import colors from "src/Themes/colors";
import fonts from "src/Themes/fonts";
import { borderWidth, isWeb, size } from "src/Themes/variables";
import { ICommute, ICompany } from "src/types";

interface IProps {
  ride: ICommute;
}

class CompanyCard extends React.PureComponent<IProps> {
  public render() {
    return (
      <StoreLoader
        lines={1}
        stateName="companies"
        elementId={this.props.ride.contractorCompanyId}
        renderData={this.renderCompany}
        loadIndicatorStyles={styles.container}
      />
    );
  }

  private renderCompany = (company: IResponse) => {
    const companyData = company.data as ICompany;
    return (
      <View style={styles.container}>
        {this.renderImage(companyData.logoId)}
        <View style={styles.textContainer}>
          <Text numberOfLines={1} style={styles.text}>
            {companyData.name}
          </Text>
        </View>
      </View>
    );
  };

  private renderImage = (logoId?: string) => {
    if (!logoId) {
      return null;
    }
    return (
      <CroppedThumbnail
        imageId={logoId}
        height={isWeb ? 128 : 44}
        style={styles.imageContainer}
        accessibilityLabel="companyLogo"
      />
    );
  };
}

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: borderWidth,
    borderBottomColor: colors.secondaryText,
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: size.l,
    paddingHorizontal: size.m,
    flexGrow: 1,
  },
  imageContainer: {
    flexGrow: 1,
  },
  textContainer: {
    flexShrink: 1,
    paddingHorizontal: size.l,
  },
  text: fonts.style.BodyRegular,
});

export default CompanyCard;
