import * as React from "react";
import { View, ViewStyle } from "react-native";
import { ParagraphPlaceholder } from "react-native-web-uikit";

interface IParagraphPlaceholderProps {
  lines?: number;
  loadIndicatorStyles?: ViewStyle;
}

const Placeholder = (props: IParagraphPlaceholderProps) => {
  return (
    <View style={props.loadIndicatorStyles}>
      <ParagraphPlaceholder lines={props.lines || 3} />
    </View>
  );
};

export default Placeholder;
