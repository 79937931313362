import * as React from "react";
import { ActivityIndicator, Linking } from "react-native";
import { connect } from "react-redux";
import { API_VERSION } from "src/Config";
import ResetPasswordForm from "src/Features/PasswordForm/ResetPasswordForm";
import { AuthNavigation, UnAuthNavigation } from "src/Router";
import { makeApiRequest } from "src/Services";
import { IUserState } from "src/types";
import UpdateAppNotation from "./Features/Common/UpdateAppNotation";
import colors from "./Themes/colors";

interface IProps {
  user: IUserState;
}

interface IState {
  isAppUpdated?: boolean;
  resetPassword: {
    isOpen: boolean;
    id?: string;
    token?: string;
  };
}

class RootContainer extends React.PureComponent<IProps, IState> {
  public state: IState = {
    resetPassword: {
      isOpen: false,
    },
  };

  public setIsAppUpdatedState = async () => {
    const version = await makeApiRequest("getMajorApiVersion");
    const appBackendVersion = Number(API_VERSION);
    this.setState({
      isAppUpdated: Number(version) <= appBackendVersion,
    });
  };

  public componentDidMount = async () => {
    await this.setIsAppUpdatedState();
    const url = await Linking.getInitialURL();
    if (url) {
      this.setResetPasswordParams(url);
    }
    Linking.addEventListener("url", this.handleOpenURL);

    this.switchToAppropriateNavigator(this.props.user);
  };

  public componentWillUnmount() {
    Linking.removeEventListener("url", this.handleOpenURL);
  }

  public componentDidUpdate = (prevProps: IProps) => {
    if (prevProps.user.data !== this.props.user.data) {
      this.switchToAppropriateNavigator(this.props.user);
    }
  };

  public render() {
    const { isAppUpdated } = this.state;
    if (isAppUpdated === undefined) {
      return <ActivityIndicator animating color={colors.themeGradient.gradientColor1} />;
    }

    if (!isAppUpdated) {
      return <UpdateAppNotation />;
    }

    return this.switchToAppropriateNavigator(this.props.user);
  }

  private handleOpenURL = (event: { url: string }) => {
    this.setResetPasswordParams(event.url);
  };

  private setResetPasswordParams = (url: string) => {
    const pathName = this.getUrlPathname(url);
    if (!pathName) {
      return;
    }
    const routeName = pathName.split("/")[1];
    if (routeName === "reset-password") {
      const id = pathName.split("/")[2];
      const token = pathName.split("/")[3];

      this.setState({
        resetPassword: {
          isOpen: true,
          id,
          token,
        },
      });
    }
  };

  private getUrlPathname = (url: string) => {
    const parser = /^([a-z][a-z0-9+\-.]*:(\/\/[^/?#]+)?)?([a-z0-9\-._~%!$&'()*+,;=:@/]*)/;
    const res = url.match(parser);
    if (!res) {
      return;
    }
    return res[3];
  };

  private closeResetPasswordForm = () =>
    this.setState({ resetPassword: { isOpen: false, id: undefined, token: undefined } });

  private switchToAppropriateNavigator = (user: IUserState) => {
    const isUserAuth = user.data && user.data._id;
    if (this.state.resetPassword.isOpen) {
      return (
        <ResetPasswordForm resetPassword={this.state.resetPassword} onNavigateBack={this.closeResetPasswordForm} />
      );
    }
    if (!isUserAuth) {
      return <UnAuthNavigation resetPassword={this.state.resetPassword} />;
    }
    return <AuthNavigation />;
  };
}

const mapStateToProps = ({ user }: { user: IUserState }) => ({
  user,
});

export default connect(mapStateToProps)(RootContainer);
