import deepAssign from "deep-assign";

type GetValue = () => string | undefined | (string | null)[] | null | void;
type Callback = Function | undefined;
type Promises = Promise<unknown>[];
type ProcessResult = (v: unknown[]) => unknown;

const mergeLocalStorageItem = (key: string, value: string) => {
  const oldValue = window.localStorage.getItem(key);
  let oldObject: {} = {};
  if (oldValue) {
    oldObject = JSON.parse(oldValue) as Object;
  }
  const newObject = JSON.parse(value) as Object;
  const nextValue = JSON.stringify(deepAssign({}, oldObject, newObject));
  window.localStorage.setItem(key, nextValue);
};

const createPromise = (getValue: GetValue, callback: Callback): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    try {
      const value = getValue();
      if (callback) {
        callback(null, value);
      }
      resolve(value);
    } catch (err) {
      if (callback) {
        callback(err);
      }
      reject(err);
    }
  });
};

const createPromiseAll = (promises: Promises, callback: Callback, processResult?: ProcessResult): Promise<unknown> => {
  return Promise.all(promises).then(
    (result) => {
      const value = processResult ? processResult(result) : null;
      if (callback) {
        callback(null, value);
      }
      return Promise.resolve(value);
    },
    (errors) => {
      if (callback) {
        callback(errors);
      }
      return Promise.reject(errors);
    },
  );
};

export class AsyncStorage {
  /**
   * Erases *all* AsyncStorage for the domain.
   */
  public clear(callback?: Function): Promise<unknown> {
    return createPromise(() => {
      window.localStorage.clear();
      return null;
    }, callback);
  }

  /**
   * (stub) Flushes any pending requests using a single batch call to get the data.
   */
  public flushGetRequests() {}

  /**
   * Gets *all* keys known to the app, for all callers, libraries, etc.
   */
  public getAllKeys(callback?: Function): Promise<unknown> {
    return createPromise(() => {
      const numberOfKeys = window.localStorage.length;
      const keys = [];
      for (let i = 0; i < numberOfKeys; i += 1) {
        const key = window.localStorage.key(i);
        keys.push(key);
      }
      return keys;
    }, callback);
  }

  /**
   * Fetches `key` value.
   */
  public getItem(key: string, callback?: Function): Promise<unknown> {
    return createPromise(() => {
      return window.localStorage.getItem(key);
    }, callback);
  }

  /**
   * multiGet resolves to an array of key-value pair arrays that matches the
   * input format of multiSet.
   *
   *   multiGet(['k1', 'k2']) -> [['k1', 'val1'], ['k2', 'val2']]
   */
  public multiGet(keys: string[], callback?: Function): Promise<unknown> {
    const promises = keys.map((key) => this.getItem(key));
    const processResult = (result: unknown[]) => result.map((value, i) => [keys[i], value]);
    return createPromiseAll(promises, callback, processResult);
  }

  /**
   * Sets `value` for `key`.
   */
  public setItem(key: string, value: string, callback?: Function): Promise<unknown> {
    return createPromise(() => {
      window.localStorage.setItem(key, value);
    }, callback);
  }

  /**
   * Takes an array of key-value array pairs.
   *   multiSet([['k1', 'val1'], ['k2', 'val2']])
   */
  public multiSet(keyValuePairs: string[][], callback?: Function): Promise<unknown> {
    const promises = keyValuePairs.map((item) => this.setItem(item[0], item[1]));
    return createPromiseAll(promises, callback);
  }

  /**
   * Merges existing value with input value, assuming they are stringified JSON.
   */
  public mergeItem(key: string, value: string, callback?: Function): Promise<unknown> {
    return createPromise(() => {
      mergeLocalStorageItem(key, value);
    }, callback);
  }

  /**
   * Takes an array of key-value array pairs and merges them with existing
   * values, assuming they are stringified JSON.
   *
   *   multiMerge([['k1', 'val1'], ['k2', 'val2']])
   */
  public multiMerge(keyValuePairs: string[][], callback?: Function): Promise<unknown> {
    const promises = keyValuePairs.map((item) => this.mergeItem(item[0], item[1]));
    return createPromiseAll(promises, callback);
  }

  /**
   * Removes a `key`
   */
  public removeItem(key: string, callback?: Function): Promise<unknown> {
    return createPromise(() => {
      window.localStorage.removeItem(key);
    }, callback);
  }

  /**
   * Delete all the keys in the `keys` array.
   */
  public multiRemove(keys: string[], callback?: Function): Promise<unknown> {
    const promises = keys.map((key) => this.removeItem(key));
    return createPromiseAll(promises, callback);
  }
}

export default new AsyncStorage();
