import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-native";
import StoreLoader, { IResponse } from "src/StoreLoader";
import { ICommute } from "src/types";
import HeaderComponent from "./HeaderComponent";

interface Props extends RouteComponentProps<{ rideId?: string }> {}

class HeaderContainer extends React.PureComponent<Props> {
  public render() {
    const { rideId } = this.props.match.params;
    if (!rideId) {
      return this.renderHeader();
    }
    return <StoreLoader stateName="commutesObjects" elementId={rideId} renderData={this.renderHeader} />;
  }

  private renderHeader = (res?: IResponse) => {
    let ride: ICommute | undefined;
    ride = res?.data as ICommute;
    return <HeaderComponent ride={ride} />;
  };
}

export default withRouter(HeaderContainer);
