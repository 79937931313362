import * as React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Avatar } from "react-native-web-uikit";
import I18n from "src/I18n";
import colors from "src/Themes/colors";
import fonts from "src/Themes/fonts";
import { IUser } from "src/types";

interface IProps {
  driver: IUser;
}

class RideDriver extends React.PureComponent<IProps> {
  public render() {
    const driver = this.props.driver;
    return (
      <View style={styles.driverNameContainer}>
        <Avatar
          size="medium"
          imageId={driver.photoId}
          source={{ uri: driver.photoUrl }}
          name={driver.lastName}
          accessibilityLabel={I18n.t("ride|cargo|driverAvatar")}
        />
        <View style={styles.propertyTextContainer}>
          <Text style={styles.property}>{driver.fullName}</Text>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  driverNameContainer: { flexDirection: "row", justifyContent: "center" },
  propertyTextContainer: {
    justifyContent: "center",
  },
  property: {
    ...fonts.style.BodySmall,
    color: colors.defaultText,
  },
});

export default RideDriver;
