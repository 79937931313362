import * as React from "react";
import { BackHandler, StyleSheet, Text, View } from "react-native";
import { Form, Icons, ThemeParamsType, ThemeProps, TransparentButton, withTheme } from "react-native-web-uikit";
import KeyboardAvoidingViewWrapper from "src/Features/Common/KeyboardAvoidingViewWrapper";
import I18n from "src/I18n";
import { Post } from "src/Redux";
import AppWrapper from "src/Wrappers/AppWrapper";

type FieldValue = {
  value?: string;
  isValid: boolean;
};
type FieldsState = {
  [key: string]: FieldValue;
};

type FormValue = {
  password: {
    value: string;
    isValid: boolean;
  };
  confirmPassword: {
    value: string;
    isValid: boolean;
  };
};

type Styles = ReturnType<typeof getStyles>;

type StateProps = {};

interface Props extends ThemeProps<Styles>, StateProps {
  resetPassword: {
    isOpen: boolean;
    id?: string;
    token?: string;
  };
  onNavigateBack(): void;
}

const validatePassword = (value: string) => {
  if (!value) {
    return I18n.t("resetPassword|errorText|passwordIsRequired");
  }
  if (value.length < 8) {
    return I18n.t("resetPassword|errorText|passwordMustBeAtLeast 8CharactersLong");
  }
  return;
};

const postUser = new Post("user");

const validateConfirmedPassword = (value: string, meta: FieldsState) => {
  if (!value) {
    return I18n.t("resetPassword|errorText|confirmedPasswordIsRequired");
  }
  if (meta.password.value !== meta.confirmPassword.value) {
    return I18n.t("resetPassword|errorText|passwordsDoNotMatch");
  }
  if (value.length < 8) {
    return I18n.t("resetPassword|errorText|passwordMustBeAtLeast 8CharactersLong");
  }
  return;
};

const ResetPasswordForm = ({ styles, resetPassword, onNavigateBack, colors }: Props) => {
  const handleBackButton = () => {
    onNavigateBack();
    return true;
  };
  React.useEffect(() => {
    BackHandler.addEventListener("hardwareBackPress", handleBackButton);
    return () => {
      BackHandler.removeEventListener("hardwareBackPress", handleBackButton);
    };
  }, []);

  const handleSubmit = async (res: FormValue) => {
    const password = res.password.value;
    await postUser.request("resetPassword", {
      password,
      confirmPassword: password,
      _id: resetPassword.id,
      resetPasswordToken: resetPassword.token,
    });
    onNavigateBack();
  };

  return (
    <AppWrapper>
      <KeyboardAvoidingViewWrapper style={styles.container}>
        <TransparentButton onPress={onNavigateBack} style={styles.backButtonContainer}>
          <Icons.ChevronLeft color={colors.defaultText} width={16} height={16} />
          <Text style={styles.backButtonText}>{I18n.t("forgotPassword|text|back")}</Text>
        </TransparentButton>
        <View style={styles.textContainer}>
          <Text style={styles.subTitle}>{I18n.t("password|label|setYourNewPassword")}</Text>
        </View>
        <Form
          fields={{
            password: {
              label: I18n.t("password|label|setNewPassword"),
              validate: validatePassword,
              secureTextEntry: true,
            },
            confirmPassword: {
              label: I18n.t("password|label|repeatNewPassword"),
              validate: validateConfirmedPassword,
              secureTextEntry: true,
            },
          }}
          submitLabel={I18n.t("password|label|setNewPassword")}
          handleSubmit={handleSubmit}
          paddingHorizontal={0}
        />
      </KeyboardAvoidingViewWrapper>
    </AppWrapper>
  );
};

const getStyles = ({ fonts, colors }: ThemeParamsType) =>
  StyleSheet.create({
    container: {
      flexGrow: 1,
      paddingHorizontal: 28,
      justifyContent: "center",
      backgroundColor: colors.background,
    },
    logoContainer: {
      paddingVertical: 24,
      alignItems: "center",
    },
    title: {
      ...fonts.Title,
      color: colors.defaultText,
    },
    subTitle: {
      ...fonts.SubTitle,
      color: colors.defaultText,
    },
    textContainer: {
      flexShrink: 1,
      marginBottom: 64,
    },
    backButtonContainer: {
      alignItems: "center",
      justifyContent: "flex-start",
      flexDirection: "row",
    },
    backButtonText: {
      color: colors.defaultText,
      marginHorizontal: 8,
    },
  });

export default withTheme<Props>(getStyles)(ResetPasswordForm);
