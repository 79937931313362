import * as React from "react";
import { StyleSheet, Text, View } from "react-native";
import Pdf from "react-native-pdf";
import { InputField, SignatureForm, TransparentButton } from "react-native-web-uikit";
import WebView from "react-native-webview";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-native";
import ModalComponent from "src/Features/Common/ModalComponent";
import { getAccuratePositionWithFallback } from "src/Helpers/locationHelper.native";
import { RIDE_STATUSES } from "src/Helpers/ridesHelpers";
import I18n from "src/I18n";
import { Put } from "src/Redux";
import Subscriber from "src/Subscriber";
import colors from "src/Themes/colors";
import fonts from "src/Themes/fonts";
import { isWeb, shadow, size } from "src/Themes/variables";
import { Coordinates, ICommute, ICustomerRole, IDeliveryStatus, IUser } from "src/types";

interface IStateProps {
  user: IUser;
}

interface IProps extends IStateProps, RouteComponentProps {
  ride: ICommute;
  customerRole: ICustomerRole;
}

interface IState {
  /**
   * Change it will trigger rerender pdf view
   */
  pdfKeyCount: number;
  isVisible: boolean;
  problemText?: string;
}

const PutRide = new Put("commutesObjects");

class StatusChanger extends React.PureComponent<IProps, IState> {
  public state: IState = {
    isVisible: false,
    pdfKeyCount: 0,
  };
  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevState.pdfKeyCount === this.state.pdfKeyCount) {
      this.setState({ pdfKeyCount: this.state.pdfKeyCount + 1 });
    }
  }

  public render() {
    const lengthCounter = `${this.state.problemText ? this.state.problemText.length : 0}/250`;
    return (
      <View style={styles.container}>
        {this.renderPdf()}
        {this.renderSignCmrConfirmationView()}
        <ModalComponent
          headerText={I18n.t("signatureModal|label|yourSignature")}
          isVisible={this.state.isVisible}
          onClose={this.hideModal}>
          {this.props.customerRole === "SENDER" ? (
            <View style={styles.inputContainer}>
              <Text style={styles.inputHeaderText}>
                {I18n.t("signatureModal|label|areThereAnyProblemsWithTheCargo?")}
              </Text>
              <InputField
                // prettier-ignore
                label={`${I18n.t("signatureModal|label|pleaseDescribeTheProblemHere",)} (${I18n.t("signatureModal|label|optional")})`}
                onChangeTextValidated={this.setProblemText}
                validateValue={this.validateProblemText}
              />
              <Text style={styles.inputHelperText}>{lengthCounter}</Text>
            </View>
          ) : null}
          <SignatureForm
            isVisible={this.state.isVisible}
            submitButtonLabel={I18n.t("signatureModal|buttonText|ok")}
            cancelButtonLabel={I18n.t("signatureModal|buttonText|erase")}
            onBackdropPress={this.hideModal}
            onSignApply={this.onSignApply}
          />
        </ModalComponent>
      </View>
    );
  }

  private setProblemText = (e: { value: string | undefined; isValid: boolean }) =>
    this.setState({ problemText: e.value });

  private validateProblemText = (e?: string) =>
    e && e.length > 250 ? I18n.t("signatureModal|error|textMustNotExceed 250Characters") : undefined;

  private updateRide = async () => {
    if (!this.state.problemText) {
      return;
    }
    const { ride } = this.props;
    const carriersReservationsAndObservations = ride.carriersReservationsAndObservations
      ? ride.carriersReservationsAndObservations.concat(this.state.problemText)
      : [this.state.problemText];
    await PutRide.request("editCommute", { carriersReservationsAndObservations }, this.props.ride._id);
  };

  private setRideStatus = async (signatureImage: string, deliveryStatus: IDeliveryStatus) => {
    let coords: Coordinates;
    try {
      const locationRes = await getAccuratePositionWithFallback();
      coords = locationRes.coords;
    } catch (e) {
      this.setState({ isVisible: false });
      const error = e as Error;
      Subscriber.showNotification({
        text: error.message,
      });
      return;
    }

    const ride: ICommute = this.props.ride;

    await PutRide.request(
      "setDeliveryStatus",
      {
        type: "Commute",
        coordinates: { lat: coords.latitude, lng: coords.longitude },
        status: deliveryStatus,
        signatureImage,
      },
      ride._id,
    );
    this.setState({ isVisible: false }, this.navigateBack);
  };

  private renderSignCmrConfirmationView = () => (
    <View style={styles.cmrConfirmationViewContainer}>
      <Text style={styles.confirmationText}>{I18n.t("cmr|label|doYouWantToSignTheDocument?")}</Text>
      <View style={styles.confirmationButtonsContainer}>
        <TransparentButton
          style={styles.cancelButtonContainer}
          onPress={this.navigateBack}
          label={I18n.t("cmr|button|cancel")}
        />
        <TransparentButton
          style={styles.submitButtonContainer}
          onPress={this.showModal}
          label={I18n.t("cmr|button|sign")}
        />
      </View>
    </View>
  );

  private navigateBack = () => {
    this.props.history.goBack();
  };
  private showModal = () => {
    this.setState({ isVisible: true });
  };

  private hideModal = () => {
    this.setState({ isVisible: false });
  };

  private onSignApply = async (data: { url: string }) => {
    if (!data.url) {
      return;
    }
    await this.updateRide();
    if (this.props.customerRole === "RECEIVER") {
      return this.setRideStatus(data.url, RIDE_STATUSES.finished);
    }
    return this.setRideStatus(data.url, RIDE_STATUSES.receivedCargo);
  };

  private renderPdf = () => {
    const { ride } = this.props;
    if (!ride) {
      return null;
    }
    if (isWeb) {
      return <WebView source={{ uri: ride.cmrDocumentPath }} style={{ flex: 1 }} key={this.state.pdfKeyCount} />;
    }
    return <Pdf source={{ uri: ride.cmrDocumentPath }} style={{ flex: 1 }} scale={1.2} key={this.state.pdfKeyCount} />;
  };
}

const mapStateToProps = ({ user }: { user: { data: IUser } }) => ({
  user: user.data,
});

const styles = StyleSheet.create({
  container: { flex: 1, justifyContent: "flex-end" },
  cmrConfirmationViewContainer: {
    height: 88,
    width: "100%",
    justifyContent: "space-around",
    padding: size.m,
    backgroundColor: colors.background,
    ...shadow,
  },
  confirmationButtonsContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  cancelButtonContainer: {
    width: undefined,
    marginHorizontal: size.s,
    textTransform: "uppercase",
    color: colors.defaultText,
  },
  submitButtonContainer: {
    width: undefined,
    marginHorizontal: size.s,
    textTransform: "uppercase",
    color: colors.themeColor,
  },
  confirmationText: fonts.style.BodyRegular,
  modalContainer: {
    padding: 0,
    margin: 0,
    backgroundColor: colors.background,
  },
  header: {
    flexDirection: "row",
    backgroundColor: colors.containerBackground,
    paddingHorizontal: size.m,
    paddingVertical: size.s,
    alignItems: "center",
    ...shadow,
  },
  headerText: {
    marginHorizontal: size.m,
    ...fonts.style.SubTitle,
    color: colors.defaultText,
  },
  inputHeaderText: {
    ...fonts.style.Title,
    color: colors.defaultText,
  },
  inputHelperText: {
    color: colors.defaultText,
  },
  inputContainer: {
    flexShrink: 1,
    padding: 16,
    backgroundColor: colors.background,
  },
});

export default connect(mapStateToProps)(withRouter(StatusChanger));
