import * as React from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-native";
import CommuteWithActualVehiclesRoutesLoader from "src/Features/RidesList/Components/CommuteWithActualVehiclesRoutesLoader";
import RidesList from "src/Features/RidesList/RidesList";
import { getMainRoute } from "src/Router";
import { selectIsFetchingRidesState, selectRidesIds, selectRidesPaginationData } from "src/Selectors/ridesSelector";
import { ICommute, IReduxStoreState } from "src/types";

interface IProps extends RouteComponentProps {
  ridesIds: string[];
  paginationData: { isLastPage: boolean; isFirstPage: boolean };
  isFetching: boolean;
  ridesListKey?: string;
  onRefresh(): void;
  onEndReached(): void;
}

interface IState {
  ridesIds: string[];
  /**
   * Change it will trigger rerender ridesList and update all rides inside it
   */
  rideListUniqueKey: number;
  currentRide?: { data: ICommute; index: number };
}

class RidesListContainer extends React.PureComponent<IProps, IState> {
  public state: IState = {
    ridesIds: this.props.ridesIds,
    rideListUniqueKey: 0,
  };

  public componentDidUpdate = (prevProps: IProps) => {
    const allPrevRidesIds = this.state.ridesIds;
    const newRidesIds = this.props.ridesIds;
    if (this.props.paginationData.isFirstPage) {
      this.setState({ ridesIds: newRidesIds });
      return;
    }
    if (prevProps.ridesIds !== newRidesIds) {
      const uniqueRidesIds = this.getUniqueRidesIds(allPrevRidesIds, newRidesIds);
      this.setState({ ridesIds: uniqueRidesIds });
      return;
    }
  };

  public render() {
    return (
      <View style={this.getRouteSize()}>
        <CommuteWithActualVehiclesRoutesLoader />
        <RidesList
          onEndReached={this.handleEndReached}
          onRefresh={this.handleRefresh}
          ridesIds={this.state.ridesIds}
          isFetching={this.props.isFetching}
          rideListUniqueKey={this.state.rideListUniqueKey}
        />
      </View>
    );
  }

  private getRouteSize = () => (this.props.location.pathname === getMainRoute() ? { flex: 1 } : { flex: 0 });

  private handleRefresh = () => {
    this.setState({ rideListUniqueKey: this.state.rideListUniqueKey + 1 }, this.props.onRefresh);
  };

  private handleEndReached = () => {
    if (!this.props.paginationData.isLastPage) {
      this.props.onEndReached();
    }
  };

  private getUniqueRidesIds = (prevRides: string[], newRides: string[]) => {
    const uniqueRidesIdsSet = new Set([...prevRides, ...newRides]);
    return Array.from(uniqueRidesIdsSet);
  };
}

const mapStateToProps = (state: IReduxStoreState) => ({
  ridesIds: selectRidesIds(state),
  paginationData: selectRidesPaginationData(state),
  isFetching: selectIsFetchingRidesState(state),
});

export default connect(mapStateToProps)(withRouter(RidesListContainer));
