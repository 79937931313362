import * as React from "react";
import { View } from "react-native";
import HeaderComponent from "src/Features/Common/RideCardComponent/HeaderComponent";
import ManagerComponent from "src/Features/Common/RideCardComponent/ManagerComponent";
import { ICommute, ICompany } from "src/types";
interface IProps {
  ride: ICommute;
}

interface IState {
  company: ICompany;
}
class DriverCard extends React.PureComponent<IProps, IState> {
  public render() {
    const { ride } = this.props;
    return (
      <View>
        <HeaderComponent ride={ride} />
        <ManagerComponent userId={ride.userId} />
      </View>
    );
  }
}

export default DriverCard;
