import * as React from "react";
import AppWrapper from "src/Wrappers/AppWrapper";

class UnAuthWrapper extends React.PureComponent {
  public render() {
    return <AppWrapper>{this.props.children}</AppWrapper>;
  }
}

export default UnAuthWrapper;
