import * as React from "react";
import StoreLoader, { IResponse, loadIndicatorsTypes } from "src/StoreLoader";
import { IUser } from "src/types";
import DriverComponent from "./DriverComponent";

interface IProps {
  driverId: string;
}

interface IState {
  destinationName: string;
}

class CargoContainer extends React.PureComponent<IProps, IState> {
  public render() {
    return (
      <StoreLoader
        type={loadIndicatorsTypes.paragraphPlaceholder}
        lines={1}
        stateName="users"
        elementId={this.props.driverId}
        renderData={this.renderDriver}
      />
    );
  }

  private renderDriver = (driver: IResponse) => {
    return <DriverComponent driver={driver.data as IUser} />;
  };
}

export default CargoContainer;
