export type NotificationEvent = {
  text: string;
};

type onShowNotification = (e: NotificationEvent) => void;

interface ISubscriber {
  subscribeShowNotification(fn: onShowNotification): void;
  showNotification(e: NotificationEvent): void;
}

class Subscriber implements ISubscriber {
  private onShowNotification?: onShowNotification;
  public subscribeShowNotification = (fn: onShowNotification) => {
    this.onShowNotification = fn;
  };
  public showNotification = (e: NotificationEvent) => {
    if (this.onShowNotification) {
      this.onShowNotification(e);
    }
  };
}

export default Subscriber;
