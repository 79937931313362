import * as React from "react";
import RideCard from "src/Features/RidesList/Components/RideCard";
import StoreLoader, { IResponse, loadIndicatorsTypes } from "src/StoreLoader";
import { ICommute } from "src/types";
interface IProps {
  rideId: string;
  rideIndex: number;
}

interface IState {
  destinationName: string;
}

class RideCardContainer extends React.PureComponent<IProps, IState> {
  public render() {
    return (
      <StoreLoader
        type={loadIndicatorsTypes.cardsPlaceholder}
        cards={1}
        stateName={"commutesObjects"}
        elementId={this.props.rideId}
        renderData={this.renderRides}
        maxAge={0}
      />
    );
  }

  private renderRides = (ride: IResponse) => {
    const rideData = ride.data as ICommute;
    return <RideCard ride={rideData} rideIndex={this.props.rideIndex} />;
  };
}

export default RideCardContainer;
