import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-native";
import StoreLoader, { IResponse, loadIndicatorsTypes } from "src/StoreLoader";
import { ICommute } from "src/types";
import StatusChanger from "./Components/StatusChanger";

interface Props extends RouteComponentProps<{ rideId: string }> {}

class DeliveryStatusChangerContainer extends React.PureComponent<Props> {
  public render() {
    const rideId = this.props.match.params.rideId;
    return (
      <StoreLoader
        type={loadIndicatorsTypes.paragraphPlaceholder}
        stateName="commutesObjects"
        elementId={rideId}
        renderData={this.renderCommute}
      />
    );
  }
  private renderCommute = (commute: IResponse) => {
    const commuteData = commute.data as ICommute;
    return <StatusChanger ride={commuteData} customerRole={commuteData.customerRole} key={commuteData._id} />;
  };
}

export default withRouter(DeliveryStatusChangerContainer);
