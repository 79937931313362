import { ActionTypes, dispatch } from "src/Redux";
import { ApiErrorData, ApiPostParams, ApiRequestNames, makeApiRequest } from "src/Services";
import { StoreStateNames } from "src/types";

class Post {
  private stateName: StoreStateNames;
  constructor(stateName: StoreStateNames) {
    this.stateName = stateName;
  }

  public request = async (requestName: ApiRequestNames, data?: ApiPostParams, id?: string) => {
    const Actions = new PostActions(this.stateName, id);
    Actions.startFetching();
    if (requestName === "logout") {
      Actions.logout();
    }
    try {
      const res = await makeApiRequest(requestName, id, data);
      return Actions.setSucceedResponse(res);
    } catch (error) {
      const apiError = error as ApiErrorData;
      return Actions.setFailedResponse(apiError);
    }
  };
}

class PostActions {
  private stateName: StoreStateNames;
  private id?: string;

  constructor(stateName: StoreStateNames, id?: string) {
    this.stateName = stateName;
    this.id = id;
  }

  public startFetching = () =>
    dispatch({
      type: ActionTypes.DATA_FETCHING_START,
      stateName: this.stateName,
      id: this.id,
    });

  public setSucceedResponse = (payload: unknown) =>
    dispatch({
      type: ActionTypes.DATA_FETCHING_SUCCEED,
      payload,
      stateName: this.stateName,
      id: this.id,
    });

  public setFailedResponse = (payload: ApiErrorData) =>
    dispatch({
      type: ActionTypes.DATA_FETCHING_FAILED,
      payload,
      stateName: this.stateName,
      id: this.id,
    });
  public logout = () =>
    dispatch({
      type: ActionTypes.LOGOUT,
    });
}

export default Post;
