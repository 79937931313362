import * as React from "react";
import { StyleSheet, Text, View } from "react-native";
import { TransparentButton } from "react-native-web-uikit";
import I18n from "src/I18n";
import NotificationIcon from "src/Images/NotificationIcon";
import colors from "src/Themes/colors";
import fonts from "src/Themes/fonts";
import { borderRadius, size } from "src/Themes/variables";

interface IProps {
  text: string;
  hideNotification(): void;
}

const GeneralNotification = (props: IProps) => {
  return (
    <View style={styles.container}>
      <View style={styles.notification}>
        <NotificationIcon />
        <Text style={styles.text}>{props.text}</Text>
      </View>
      <TransparentButton
        label={<Text style={styles.buttonText}>{I18n.t("notification|button|ok")}</Text>}
        onPress={props.hideNotification}
        style={styles.button}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "space-between",
    paddingHorizontal: 8,
    borderBottomColor: colors.palette.veryLightGray,
    borderBottomWidth: 1,
  },
  notification: {
    paddingVertical: size.l,
    borderRadius,
    flexDirection: "row",
    alignItems: "center",
  },
  button: { marginHorizontal: 8 },
  buttonText: {
    color: colors.themeColor,
    textTransform: "uppercase",
  },
  text: { ...fonts.style.BodyRegular, marginHorizontal: 8 },
});

export default GeneralNotification;
