import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-native";
import { getMainRoute, getMapRoute } from "src/Router";
import StoreLoader, { IResponse, loadIndicatorsTypes } from "src/StoreLoader";
import { ICommute } from "src/types";

interface IProps extends RouteComponentProps<undefined, {}, { rideId?: string; index?: string }> {}

class RideCardContainer extends React.PureComponent<IProps> {
  public render() {
    return this.renderLoader();
  }

  private renderLoader = () => {
    const rideId = this.props.location.state?.rideId;
    if (!rideId) {
      return null;
    }
    return (
      <StoreLoader
        type={loadIndicatorsTypes.progressBar}
        stateName="commutesWithActualVehiclesRoutes"
        elementId={rideId}
        renderData={this.renderCommute}
      />
    );
  };

  private renderCommute = (ride: IResponse) => {
    const rideData = ride.data as ICommute;
    this.props.history.push(getMainRoute(), undefined);
    this.props.history.push(getMapRoute(rideData._id, this.props.location.state.index!));
    return null;
  };
}

export default withRouter(RideCardContainer);
