import { I18nManager, Platform } from "react-native";
import reactNativeI18n from "react-native-i18n";
import enJson from "src/I18n/languages/en.json";
import esJson from "src/I18n/languages/es.json";
import frJson from "src/I18n/languages/fr.json";
import heJson from "src/I18n/languages/he.json";
import itJson from "src/I18n/languages/it.json";
import roJson from "src/I18n/languages/ro.json";

export const languageCodes = {
  he: "he",
  fr: "fr",
  en: "en",
  it: "it",
  ro: "ro",
  es: "es",
} as const;

if (Platform.OS === "web") {
  reactNativeI18n.locale = navigator.language;
}

export const locale = reactNativeI18n.locale.substr(0, 2) as keyof typeof languageCodes;

export default () => {
  // Enable fallbacks if you want `en-US` and `en-GB` to fallback to `en`
  reactNativeI18n.fallbacks = true;

  // English language is the main language for fall back:
  reactNativeI18n.translations = {
    en: enJson,
  };

  let languageCode = "";

  switch (locale) {
    case languageCodes.he:
      languageCode = languageCodes.he;
      break;
    case languageCodes.fr:
      languageCode = languageCodes.fr;
      break;
    case languageCodes.it:
      languageCode = languageCodes.it;
      break;
    case languageCodes.ro:
      languageCode = languageCodes.ro;
      break;
    case languageCodes.es:
      languageCode = languageCodes.es;
      break;
    default:
      languageCode = languageCodes.en;
  }

  const currentJsonale = reactNativeI18n.currentLocale();
  const isJsonale = (code: string) => currentJsonale.indexOf(code) === 0;
  const isRTL = isJsonale(languageCodes.he);

  I18nManager.allowRTL(isRTL);

  // All other translations for the app goes to the respective language file:
  switch (languageCode) {
    case languageCodes.he:
      reactNativeI18n.translations.he = heJson;
      break;
    case languageCodes.fr:
      reactNativeI18n.translations.fr = frJson;
      break;
    case languageCodes.it:
      reactNativeI18n.translations.it = itJson;
      break;
    case languageCodes.ro:
      reactNativeI18n.translations.ro = roJson;
      break;
    case languageCodes.es:
      reactNativeI18n.translations.es = esJson;
      break;
    default:
      reactNativeI18n.translations.en = enJson;
  }

  return currentJsonale;
};
