import { ActionTypes, dispatch, getState } from "src/Redux";
import { ApiRequestNames, makeApiRequest } from "src/Services";
import { IndexedCollectionStateValues, KeyedCollectionStateValues, StoreStateNames, StoreStateValues } from "src/types";

class Delete {
  private stateName: StoreStateNames;
  constructor(stateName: StoreStateNames) {
    this.stateName = stateName;
  }
  public request = async (requestName: ApiRequestNames, id: string) => {
    const Actions = new DeleteActions(this.stateName, id);
    Actions.setSucceedResponse();
    try {
      await makeApiRequest(requestName, id);
    } catch (error) {
      Actions.setFailedResponse();
    }
  };
}

class DeleteActions {
  private stateName: StoreStateNames;
  private currentState: StoreStateValues;
  private id: string;
  constructor(stateName: StoreStateNames, id: string) {
    this.stateName = stateName;
    this.id = id;
    this.currentState = getState()[stateName] as IndexedCollectionStateValues;
    const keyedCollectionState = getState()[stateName] as KeyedCollectionStateValues;
    if (id) {
      this.currentState = keyedCollectionState[id];
    }
  }

  public setSucceedResponse = () => {
    dispatch({
      type: ActionTypes.DATA_FETCHING_SUCCEED,
      payload: undefined,
      stateName: this.stateName,
      id: this.id,
    });
  };

  public setFailedResponse = () => {
    dispatch({
      type: ActionTypes.DATA_FETCHING_SUCCEED,
      payload: this.currentState.data,
      stateName: this.stateName,
      id: this.id,
    });
  };
}

export default Delete;
