import * as React from "react";

import { Polyline as LeafletPolyline } from "react-leaflet";
import colors from "src/Themes/colors";

const polylineOptions = {
  ride: {
    color: colors.defaultText,
    weight: 5,
  },
  truckRoute: {
    color: colors.themeColor,
    weight: 4,
  },
};

export type PolylineType = "ride" | "truckRoute";

type Props = {
  positions: [number, number][];
  type: PolylineType;
};

export const Polyline = ({ positions, type }: Props) => (
  <LeafletPolyline positions={positions} {...polylineOptions[type]} />
);
