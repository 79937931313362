import * as polyline from "@mapbox/polyline";
import * as R from "ramda";
import { Region } from "src/types";

export const getDirections = (points?: string) => {
  if (!points) {
    return;
  }
  const cPoints = polyline.decode(points);
  const coords = cPoints.map((point) => {
    return {
      latitude: point[0],
      longitude: point[1],
    };
  });
  const initialRegion = setInitialRegionDelta(coords);

  return { coords, initialRegion };
};

export const setInitialRegionDelta = (coords: { latitude: number; longitude: number }[]): Region => {
  const latArr = R.map(R.prop("latitude"), coords);
  const longArr = R.map(R.prop("longitude"), coords);
  const subtractMaxMin = R.converge(R.subtract, [R.apply(Math.max), R.apply(Math.min)]);
  const middleIndex = Math.round(coords.length / 2);

  const initialRegion = {
    ...coords[middleIndex], // there we're taking middle coordinate
    // there we're calculate the deltaLat
    latitudeDelta: (subtractMaxMin(latArr) as number) + 10,
    // there we're calculate the deltaLong
    longitudeDelta: subtractMaxMin(longArr),
  };

  return { ...initialRegion };
};
