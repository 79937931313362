import { Platform } from "react-native";
import reactNativeConfig from "react-native-config";
import { deviceCountryIsoCode } from "src/Helpers/countryHelper";
import { isMobileApp } from "src/Themes/variables";

type EnvConfig = {
  ENV?: string;
  BASE_URL_EU?: string;
  BASE_URL_IL?: string;
  API_VERSION?: string;
  GOOGLE_MAPS_API_KEY?: string;
  PTV_VERSION?: string;
  PTV_API_KEY?: string;
  PTV_ENV?: string;
};

declare const webConfig: EnvConfig;
const mobileConfig = reactNativeConfig as EnvConfig;

let config: EnvConfig;

if (isMobileApp) {
  config = mobileConfig;
} else {
  config = webConfig;
}

class AppEnvConfig {
  private envConfig: EnvConfig = config;
  private getEnvVariable = (variableName: keyof EnvConfig) => {
    if (!this.envConfig[variableName]) {
      throw new Error(`Required environment variable: ${variableName} is undefined`);
    }
    return this.envConfig[variableName];
  };
  private getBaseUrl = () => {
    if (deviceCountryIsoCode === "IL") {
      return this.getEnvVariable("BASE_URL_IL");
    }
    return this.getEnvVariable("BASE_URL_EU");
  };

  private getGoogleMapsApiKey = () => {
    if (Platform.OS !== "android") {
      return;
    }
    return this.getEnvVariable("GOOGLE_MAPS_API_KEY");
  };

  private getPtvVariables = () => {
    if (Platform.OS !== "web") {
      return undefined;
    }
    return {
      PTV_VERSION: this.getEnvVariable("PTV_VERSION"),
      PTV_API_KEY: this.getEnvVariable("PTV_API_KEY"),
      PTV_ENV: this.getEnvVariable("PTV_ENV"),
    };
  };

  public env = this.getEnvVariable("ENV");
  public apiVersion = this.getEnvVariable("API_VERSION");
  public googleMapsApiKey = this.getGoogleMapsApiKey();
  public baseUrl = this.getBaseUrl();
  public region = deviceCountryIsoCode === "IL" ? "ISRAEL" : "EUROPE";
  public ptv = this.getPtvVariables();
}

export default AppEnvConfig;
