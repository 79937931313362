import * as React from "react";
import { StyleSheet } from "react-native";
import { MenuProvider } from "react-native-popup-menu";
import colors from "src/Themes/colors";
import AppWrapper from "src/Wrappers/AppWrapper";

interface Props {}

class AuthWrapper extends React.PureComponent<Props> {
  public render() {
    return (
      <AppWrapper>
        <MenuProvider style={styles.container}>{this.props.children}</MenuProvider>
      </AppWrapper>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexShrink: 1,
    justifyContent: "center",
    backgroundColor: colors.background,
  },
  notificationContainer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
});

export default AuthWrapper;
