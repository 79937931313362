import { ActionTypes, dispatch, getState } from "src/Redux";
import { ApiPutParams, ApiRequestNames, makeApiRequest } from "src/Services";
import { IndexedCollectionStateValues, KeyedCollectionStateValues, StoreStateNames } from "src/types";

class Put {
  private stateName: StoreStateNames;
  constructor(stateName: StoreStateNames) {
    this.stateName = stateName;
  }

  public request = async (requestName: ApiRequestNames, data: ApiPutParams, id?: string) => {
    const Actions = new PutActions(this.stateName, id);
    Actions.setSucceedResponse(data);
    try {
      const res = await makeApiRequest(requestName, id, data);
      Actions.setSucceedResponse(res);
    } catch (error) {
      Actions.setFailedResponse();
    }
  };
}

class PutActions {
  private stateName: StoreStateNames;
  private currentState: KeyedCollectionStateValues["keys"] | IndexedCollectionStateValues;
  private id?: string;

  constructor(stateName: StoreStateNames, id?: string) {
    this.stateName = stateName;
    this.id = id;
    const keyedState = getState()[stateName] as KeyedCollectionStateValues;
    if (id && keyedState[id]) {
      this.currentState = keyedState[id];
    } else {
      this.currentState = getState()[stateName] as IndexedCollectionStateValues;
    }
  }

  public setSucceedResponse = (payload: unknown) => {
    return dispatch({
      type: ActionTypes.DATA_FETCHING_SUCCEED,
      payload: this.getUpdatedItem(this.currentState.data, payload),
      stateName: this.stateName,
      id: this.id,
    });
  };
  public setFailedResponse = () => {
    dispatch({
      type: ActionTypes.DATA_FETCHING_SUCCEED,
      payload: this.currentState,
      stateName: this.stateName,
      id: this.id,
    });
  };

  private getUpdatedItem = (
    item: KeyedCollectionStateValues["key"]["data"] | IndexedCollectionStateValues["data"],
    payload: unknown,
  ) => {
    const updatedItemPayload = payload as Object;
    if (item && !this.id) {
      return { ...item, ...updatedItemPayload };
    }
    const keyedItem = item as KeyedCollectionStateValues["key"]["data"];
    if (keyedItem && keyedItem._id === this.id) {
      return { ...item, ...updatedItemPayload };
    }

    return item;
  };
}

export default Put;
