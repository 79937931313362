import * as React from "react";
import { StyleSheet, Text, View } from "react-native";
import colors from "src/Themes/colors";
import fonts from "src/Themes/fonts";
import { size } from "src/Themes/variables";

interface IProps {
  label: string;
  children: React.ReactElement;
}

const RideProperty = ({ label, children }: IProps) => (
  <View style={styles.propertyContainer}>
    <Text style={styles.label}>{label}</Text>
    {children}
  </View>
);

const styles = StyleSheet.create({
  propertyContainer: {
    marginHorizontal: size.m,
  },
  label: {
    ...fonts.style.Subheading,
    color: colors.subtitle,
  },
});

export default RideProperty;
