import * as React from "react";
import { View, ViewStyle } from "react-native";
import { connect } from "react-redux";
import { Delete, Get, Post, Put, RequestTimeInterval } from "src/Redux";
import { ApiGetParams, ApiPostParams, ApiPutParams, ApiRequestNames } from "src/Services";
import {
  IndexedCollectionStateValues,
  IReduxStore,
  KeyedCollectionStateValues,
  StoreStateNames,
  StoreStateValues,
} from "src/types";
import { CardsPlaceholder, MapPlaceholder, ParagraphPlaceholder, ProgressBar } from "./LoadIndicators";

interface IResponseMethods {
  get(): void;
  updateById(values: ApiPutParams, itemId: string, requestName?: string): void;
  deleteById(itemId: string, requestName?: string): void;
  post(values: ApiPostParams, requestName?: string): void;
}

export interface IResponse extends IResponseMethods {
  data: StoreStateValues["data"];
}

export enum loadIndicatorsTypes {
  paragraphPlaceholder = "paragraphPlaceholder",
  mapPlaceholder = "mapPlaceholder",
  cardsPlaceholder = "cardsPlaceholder",
  progressBar = "progressBar",
}

interface IParagraphProps {
  lines?: number;
  cards?: number;
}

interface IProps extends IParagraphProps {
  type: loadIndicatorsTypes;
  store: IReduxStore;
  stateName: StoreStateNames;
  elementId?: string;
  requestName?: ApiRequestNames;
  loadIndicatorStyles?: ViewStyle;
  params?: ApiGetParams;
  text?: string;
  refreshEvery?: RequestTimeInterval;
  updateOnChangeState?: StoreStateNames[];
  maxAge?: number;
  renderData(res: IResponse): React.ReactNode;
}

class StoreLoader extends React.Component<IProps> {
  public static defaultProps = {
    type: loadIndicatorsTypes.paragraphPlaceholder,
  };
  private getElement = this.setGetElementRequest();

  public componentDidMount = async () => {
    this.requestElementWithTimeInterval();
    return this.getRequest();
  };

  public componentDidUpdate = async (prevProps: IProps) => {
    if (prevProps.elementId !== this.props.elementId || prevProps.params !== this.props.params) {
      await this.getElement.request(this.props.requestName, this.props.elementId, this.props.params);
    }
  };

  public shouldComponentUpdate = (nextProps: IProps) => {
    const currentStoreState = this.getStoreStateData();
    const nextStoreState = this.getStoreStateData(nextProps);
    if (nextProps.elementId !== this.props.elementId) {
      return true;
    }
    if (nextProps.params !== this.props.params) {
      return true;
    }
    if (nextStoreState !== currentStoreState) {
      return true;
    }

    if (!this.props.updateOnChangeState) {
      return false;
    }

    return this.isUpdateOnChangeState(nextProps);
  };

  public componentWillUnmount = () => {
    this.clearRequestTimeInterval();
  };

  public render() {
    const storeStateData = this.getStoreStateData();
    const isElementFetchedSucceed = this.isComponentReadyToRender(storeStateData);
    if (isElementFetchedSucceed) {
      return this.renderComponent(storeStateData);
    }
    if (storeStateData && storeStateData.isError) {
      return this.renderErrorPlaceholder();
    }

    return <View style={this.props.loadIndicatorStyles}>{this.renderPlaceholder()}</View>;
  }

  private getRequest = () => this.getElement.request(this.props.requestName, this.props.elementId, this.props.params);

  private isUpdateOnChangeState = (nextProps: IProps) => {
    const updateOnChangeState = this.props.updateOnChangeState as StoreStateNames[];
    for (const stateName of updateOnChangeState) {
      if (nextProps.store[stateName] !== this.props.store[stateName]) {
        return true;
      }
    }
    return false;
  };

  private setGetElementRequest() {
    return new Get(this.props.stateName, this.props.maxAge);
  }

  private requestElementWithTimeInterval = () => {
    const { refreshEvery, params, elementId, requestName } = this.props;
    if (Boolean(refreshEvery)) {
      this.getElement.requestWithTimeInterval(refreshEvery, requestName, elementId, params);
    }
  };

  private renderPlaceholder = () => {
    switch (this.props.type) {
      case loadIndicatorsTypes.cardsPlaceholder:
        return <CardsPlaceholder cards={this.props.cards} />;
      case loadIndicatorsTypes.mapPlaceholder:
        return <MapPlaceholder lines={this.props.lines} />;
      case loadIndicatorsTypes.progressBar:
        return <ProgressBar />;
      case loadIndicatorsTypes.paragraphPlaceholder:
        return <ParagraphPlaceholder lines={this.props.lines} loadIndicatorStyles={this.props.loadIndicatorStyles} />;
    }
  };

  private updateByIdRequest = async (values: ApiPutParams, itemId: string, requestName: ApiRequestNames) => {
    const putStateName = this.props.stateName;
    const putElement = new Put(putStateName);
    await putElement.request(requestName, values, itemId);
  };

  private postRequest = async (values: ApiPostParams, requestName: ApiRequestNames) => {
    const postStateName = this.props.stateName;
    const postElement = new Post(postStateName);
    await postElement.request(requestName, values);
  };

  private deleteByIdRequest = async (itemId: string, requestName: ApiRequestNames) => {
    const deleteStateName = this.props.stateName;
    const deleteElement = new Delete(deleteStateName);
    await deleteElement.request(requestName, itemId);
  };

  private renderComponent = (element: StoreStateValues) => {
    if (element.data) {
      return this.props.renderData({
        data: element.data,
        get: this.getRequest,
        updateById: this.updateByIdRequest,
        deleteById: this.deleteByIdRequest,
        post: this.postRequest,
      });
    }
    return null;
  };

  private clearRequestTimeInterval = () => {
    this.getElement.clearRequestTimeInterval();
  };

  private getStoreStateData = (nextProps?: IProps): StoreStateValues => {
    const { store, stateName, elementId } = nextProps || this.props;
    if (elementId) {
      const objectStoreState = store[stateName] as KeyedCollectionStateValues;
      return objectStoreState[elementId];
    }
    const currentState = store[stateName] as IndexedCollectionStateValues;
    return currentState;
  };

  private isComponentReadyToRender = (storeState: StoreStateValues) => {
    if (storeState && storeState.data) {
      return true;
    }
    return false;
  };

  private renderErrorPlaceholder = () => {
    return <View style={this.props.loadIndicatorStyles} />;
  };
}

const mapStateToProps = (state: IReduxStore) => ({
  store: state,
});

export default connect(mapStateToProps)(StoreLoader);
