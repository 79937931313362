import { ActionTypes, dispatch, getState } from "src/Redux";
import { ApiErrorData, ApiGetParams, ApiRequestNames, makeApiRequest } from "src/Services";
import { IndexedCollectionStateValues, KeyedCollectionStateValues, StoreStateNames, StoreStateValues } from "src/types";

enum defaultGetRequestNames {
  companies = "getCompany",
  rides = "getCommutes",
  cargoTypes = "getCargoTypes",
  user = "getUser",
  users = "getUser",
  commutesWithActualVehiclesRoutes = "getCommuteWithActualVehiclesRoutes",
  commutesObjects = "getCommute",
  vehicles = "getVehicle",
}

export type RequestTimeInterval = undefined | number;

class Get {
  private stateName: StoreStateNames;
  private requestInterval?: number;
  private maxAge?: number;
  private expiresAt?: Date;

  constructor(stateName: StoreStateNames, maxAge?: number) {
    this.stateName = stateName;
    this.maxAge = maxAge;
    this.setExpireAt();
  }
  public requestWithTimeInterval = (
    requestTimeInterval: RequestTimeInterval,
    requestName?: ApiRequestNames,
    id?: string,
    params?: ApiGetParams,
  ) => {
    this.clearRequestTimeInterval();
    this.requestInterval = setInterval(() => {
      void this.request(requestName, id, params, requestTimeInterval);
    }, requestTimeInterval);
  };
  public clearRequestTimeInterval = () => {
    clearInterval(this.requestInterval);
  };

  public request = async (
    requestName?: ApiRequestNames,
    id?: string,
    params?: ApiGetParams,
    requestTimeInterval?: RequestTimeInterval,
  ) => {
    const isExpired = this.isExpired();
    if (isExpired) {
      this.setExpireAt();
    }
    const getRequestName = () => {
      if (requestName) {
        return requestName;
      }
      return defaultGetRequestNames[this.stateName];
    };

    const Actions = new GetActions(this.stateName, isExpired, params, id, requestTimeInterval);
    if (!Actions.isStoreUpdateRequired()) {
      return;
    }

    if (!Actions.isFetchingStarted()) {
      Actions.startFetching();
      try {
        const res = await makeApiRequest(getRequestName(), id, params);
        Actions.setSucceedResponse(res);
      } catch (error) {
        const apiError = error as ApiErrorData;
        Actions.setFailedResponse(apiError);
        return;
      }
    }
    return;
  };
  private isExpired = () => {
    return this.expiresAt && new Date(this.expiresAt).getTime() <= new Date().getTime();
  };

  private setExpireAt = () => {
    if (this.maxAge !== undefined) {
      this.expiresAt = new Date(this.maxAge * 1000 + new Date().getTime());
    }
  };
}

class GetActions {
  private stateName: StoreStateNames;
  private currentState: StoreStateValues;
  private id?: string;
  private params?: ApiGetParams;
  private requestTimeInterval?: RequestTimeInterval;
  private isExpired?: Boolean;

  constructor(
    stateName: StoreStateNames,
    isExpired?: Boolean,
    params?: ApiGetParams,
    id?: string,
    requestTimeInterval?: RequestTimeInterval,
  ) {
    this.stateName = stateName;
    this.params = params;
    this.id = id;
    this.isExpired = isExpired;
    this.requestTimeInterval = requestTimeInterval;
    this.currentState = getState()[stateName] as IndexedCollectionStateValues;
    const keyedCollectionState = getState()[stateName] as KeyedCollectionStateValues;
    if (id) {
      this.currentState = keyedCollectionState[id];
    }
  }

  public isStoreUpdateRequired = () => {
    if (this.isExpired) {
      return true;
    }

    if (this.id && this.currentState && !this.currentState.isError) {
      return false;
    }

    if (!this.params && this.currentState && this.currentState.data && !this.currentState.isError) {
      return false;
    }

    return true;
  };

  public isFetchingStarted = () => {
    if (this.currentState) {
      return !!this.currentState.isFetching;
    }

    return false;
  };

  public startFetching = () => {
    if (!this.requestTimeInterval) {
      dispatch({
        type: ActionTypes.DATA_FETCHING_START,
        stateName: this.stateName,
        id: this.id,
      });
    }
  };
  public setSucceedResponse = (payload: unknown) => {
    dispatch({
      type: ActionTypes.DATA_FETCHING_SUCCEED,
      payload,
      stateName: this.stateName,
      id: this.id,
    });
  };
  public setFailedResponse = (payload: ApiErrorData) =>
    dispatch({
      type: ActionTypes.DATA_FETCHING_FAILED,
      payload,
      stateName: this.stateName,
      id: this.id,
    });
}

export default Get;
