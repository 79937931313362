import { createSelector } from "reselect";
import { ICommute, ICommutesState } from "src/types";

const getRidesIds = (rides: ICommute[]) => {
  return rides.map((ride) => ride._id);
};

const getRides = (state: { rides: ICommutesState }) => {
  if (state.rides.data) {
    return state.rides.data.commutes;
  }
  return [];
};
const getIsFetchingRidesState = (state: { rides: ICommutesState }) => state.rides.isFetching;

const getRidesPaginationData = (state: { rides: ICommutesState }) => {
  if (state.rides.data) {
    return { currentPage: state.rides.data.currentPage, maxPages: state.rides.data.maxPages };
  }
  return { currentPage: 0, maxPages: 0 };
};

export const selectRides = createSelector(getRides, (rides) => rides);

export const selectRidesIds = createSelector(selectRides, getRidesIds);

export const selectIsFetchingRidesState = createSelector(getIsFetchingRidesState, (isFetching) => isFetching);

export const selectRidesPaginationData = createSelector(getRidesPaginationData, (paginationData) => ({
  isFirstPage: paginationData.currentPage === 0,
  isLastPage: paginationData.currentPage >= paginationData.maxPages - 1,
}));
