import * as React from "react";
import { LoginForm } from "react-native-web-uikit";
import { RouteComponentProps, withRouter } from "react-router-native";
import KeyboardAvoidingViewWrapper from "src/Features/Common/KeyboardAvoidingViewWrapper";
import Notification from "src/Features/Common/Notification";
import { validateEmail, validatePassword } from "src/Helpers/validateHelpers";
import I18n from "src/I18n";
import { Post } from "src/Redux";
import colors from "src/Themes/colors";

interface IForm extends RouteComponentProps {
  initialValues: {
    email?: string;
    password?: string;
  };
}

class LoginFormContainer extends React.PureComponent<IForm> {
  public static defaultProps = {
    initialValues: {
      email: undefined,
      password: undefined,
    },
  };
  public render() {
    const { initialValues } = this.props;
    return (
      <KeyboardAvoidingViewWrapper>
        <LoginForm
          fields={{
            email: {
              label: I18n.t("forms|fields|email"),
              initialValue: initialValues.email,
              validate: validateEmail,
            },
            password: {
              label: I18n.t("forms|fields|password"),
              initialValue: initialValues.password,
              validate: validatePassword,
            },
          }}
          callback={{
            handleSubmit: this.handleSubmit,
            onForgotPasswordPress: this.onForgotPasswordPress,
          }}
          text={{
            submitLabel: I18n.t("forms|fields|signIn"),
            forgotPasswordButtonLabel: I18n.t("forms|fields|forgotPassword?"),
          }}>
          <Notification
            timeout={10000}
            isFormNotification
            notificationStyles={{ color: colors.palette.lightRed, textColor: colors.palette.torchRed }}
          />
        </LoginForm>
      </KeyboardAvoidingViewWrapper>
    );
  }

  private handleSubmit = async (data: { email: string; password: string }) => {
    const PostUser = new Post("user");
    await PostUser.request("login", { ...data, accessRoles: ["CUSTOMER"] });
  };

  private onForgotPasswordPress = () => this.props.history.push("password");
}

export default withRouter(LoginFormContainer);
