import * as React from "react";

import { LayerGroup } from "react-leaflet";
import { markerOptions } from "src/Features/Common/MapView/Map.web/config";
import { Marker } from "./Marker";
import { Polyline, PolylineType } from "./Polyline";

type Props = {
  polylineType: PolylineType;
  route: [number, number][];
  titles?: {
    origin: React.ReactNode;
    destination: React.ReactNode;
  };
};

export const Route = ({ polylineType, route, titles }: Props) => {
  const destinationMarker = polylineType === "ride" ? markerOptions.destination : markerOptions.vehicle;
  return (
    <LayerGroup>
      <Marker title={titles?.origin} position={route[0]} icon={markerOptions.origin} />
      <Polyline positions={route} type={polylineType} />
      <Marker title={titles?.destination} position={route[route.length - 1]} icon={destinationMarker} />
    </LayerGroup>
  );
};
