import I18n from "src/I18n";
import moment from "src/moment";

export const timeFormat = (date: Date) => {
  return moment(date).format("HH:MM");
};

export const dayFormat = (date: Date) => {
  if (moment(date).isSame(moment(), "days")) {
    return I18n.t("common|moment|today");
  }
  return moment(date)
    .format("ll")
    .replace(`${moment().year()}`, "")
    .replace(",", "")
    .trim();
};

export const dateToEtaFormat = (date: Date) => {
  return `${dayFormat(date)}, ${timeFormat(date)}`;
};
