import * as React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { RouteComponentProps, withRouter } from "react-router-native";
import DriversComponent from "src/Features/Common/RideCardComponent/DriversComponent";
import HeaderComponent from "src/Features/Common/RideCardComponent/HeaderComponent";
import RideProgressCardComponent from "src/Features/Common/RideCardComponent/RideProgressCardComponent";
import ChangeStatusButtonContainer from "src/Features/RidesList/Containers/ChangeStatusButtonContainer";
import { getMainRoute } from "src/Router";
import colors from "src/Themes/colors";
import { borderRadius, shadow, size } from "src/Themes/variables";
import { ICommute, ICustomerRole } from "src/types";

interface IProps extends RouteComponentProps {
  ride: ICommute;
  rideIndex: number;
}

interface IState {
  destinationName: string;
}

class RideCard extends React.PureComponent<IProps, IState> {
  public state = {
    destinationName: this.props.ride.destinationName,
  };
  public customerRole: ICustomerRole | undefined;

  public render() {
    const { ride } = this.props;
    return (
      <View style={styles.container}>
        <TouchableOpacity onPress={this.setRide}>
          <HeaderComponent ride={ride} />
          <RideProgressCardComponent ride={this.props.ride} />
        </TouchableOpacity>
        <DriversComponent ride={ride} />
        <ChangeStatusButtonContainer ride={ride} />
      </View>
    );
  }

  private setRide = () => {
    this.props.history.push(getMainRoute(), { rideId: this.props.ride._id, index: this.props.rideIndex });
  };
}

export default withRouter(RideCard);

const styles = StyleSheet.create({
  container: {
    marginVertical: size.m,
    marginHorizontal: size.xs,
    backgroundColor: colors.background,
    borderRadius,
    ...shadow,
  },
  cargoButtonContainer: { padding: size.l },
});
