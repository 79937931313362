import * as React from "react";
import { StyleSheet, Text, View } from "react-native";
import { Icons } from "react-native-web-uikit";
import I18n from "src/I18n";
import colors from "src/Themes/colors";

class UpdateAppNotation extends React.PureComponent {
  public render() {
    return (
      <View style={styles.container}>
        <Icons.Alert width={128} height={128} color={colors.error} />
        <Text style={styles.txt}>{I18n.t("text|notatiom|appOutdated")}</Text>
      </View>
    );
  }
}

export default UpdateAppNotation;

const styles = StyleSheet.create({
  container: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  txt: {
    fontSize: 24,
    textAlign: "center",
  },
});
