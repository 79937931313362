import * as React from "react";
import { StyleSheet } from "react-native";
import { RouteComponentProps, withRouter } from "react-router-native";
import StoreLoader, { IResponse } from "src/StoreLoader";
import RideLoader from "./RideLoader";

interface IProps extends RouteComponentProps<{ rideId: string }> {}

class RideCardContainer extends React.PureComponent<IProps> {
  public commute: IResponse | undefined;
  public render() {
    return (
      <StoreLoader
        stateName="commutesWithActualVehiclesRoutes"
        elementId={this.props.match.params.rideId}
        renderData={this.renderCommute}
        updateOnChangeState={["commutesObjects"]}
        refreshEvery={30000}
        loadIndicatorStyles={styles.loadIndicatorStyle}
        maxAge={0}
      />
    );
  }

  private renderCommute = (commuteWithActualVehiclesRoutes: IResponse) => {
    return <RideLoader rideId={this.props.match.params.rideId} />;
  };
}

const styles = StyleSheet.create({
  loadIndicatorStyle: {
    flexGrow: 1,
    flexBasis: "100%",
  },
});

export default withRouter(RideCardContainer);
