import * as React from "react";
import { FlatList, SectionListProps, StyleSheet, View } from "react-native";
import RideCardContainer from "src/Features/RidesList/Containers/RideCardContainer";
import colors from "src/Themes/colors";
import { size } from "src/Themes/variables";
import { ICommute } from "src/types";

interface IProps {
  ridesIds: string[];
  isFetching: boolean;
  onEndReached: SectionListProps<ICommute>["onEndReached"];
  onRefresh: SectionListProps<ICommute>["onRefresh"];
  rideListUniqueKey: number;
}

class RidesList extends React.PureComponent<IProps> {
  public render() {
    return (
      <View style={styles.flatListContainer}>
        <FlatList
          key={this.props.rideListUniqueKey}
          style={styles.container}
          contentContainerStyle={styles.flatList}
          data={this.props.ridesIds}
          renderItem={this.renderItem}
          keyExtractor={this.getItemKey}
          showsVerticalScrollIndicator={false}
          initialNumToRender={2}
          onEndReached={this.props.onEndReached}
          onEndReachedThreshold={0.3}
          refreshing={this.props.isFetching}
          onRefresh={this.props.onRefresh}
          testID="ridesFlatList"
        />
      </View>
    );
  }

  private getItemKey = (item: string) => item;

  private renderItem = ({ item, index }: { item: string; index: number }) => {
    return <RideCardContainer key={item} rideId={item} rideIndex={index + 1} />;
  };
}

export default RidesList;

const styles = StyleSheet.create({
  flatListContainer: { flex: 1 },
  container: { backgroundColor: colors.background, flex: 0 },
  flatList: { paddingHorizontal: size.l, flex: 0 },
});
