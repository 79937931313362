import * as React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import Pdf from "react-native-pdf";
import { Menu, MenuOption, MenuOptions, MenuTrigger } from "react-native-popup-menu";
import { Avatar, Icons } from "react-native-web-uikit";
import WebView from "react-native-webview";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-native";
import ModalComponent from "src/Features/Common/ModalComponent";
import Notification from "src/Features/Common/Notification";
import I18n from "src/I18n";
import { Post } from "src/Redux";
import { AuthRouteNames } from "src/Router";
import colors from "src/Themes/colors";
import fonts from "src/Themes/fonts";
import { isMobileApp, shadow, size } from "src/Themes/variables";
import { ICommute, IReduxStoreState, IUser } from "src/types";

interface IProps extends RouteComponentProps<{ rideIndex?: string; rideId?: string }> {
  user: IUser;
  ride?: ICommute;
}
interface IState {
  isCmrVisible: boolean;
}
class Header extends React.PureComponent<IProps, IState> {
  public state = {
    isCmrVisible: false,
  };
  public render() {
    return (
      <>
        <View style={styles.container}>
          {this.renderPrimaryBar()}
          {this.renderSecondaryBar()}
          {this.renderCmrModal()}
        </View>
        <Notification />
      </>
    );
  }

  private isPathActive = (pathName: string) => !this.props.location.pathname.indexOf(`/${pathName}`);

  private renderPrimaryBar = () => {
    if (this.isPathActive(AuthRouteNames.Map)) {
      return (
        <View style={styles.sideBarContainer}>
          {this.renderBackButton()}
          <Text style={styles.label}>{`${I18n.t("header|label|ride")} #${this.getRideIndex()}`}</Text>
        </View>
      );
    }
    if (this.isPathActive(AuthRouteNames.DeliveryStatus)) {
      return (
        <View style={styles.sideBarContainer}>
          {this.renderBackButton()}
          <Text style={styles.label}>{I18n.t("deliveryStatus|label|document")}</Text>
        </View>
      );
    }
    return (
      <View style={styles.sideBarContainer}>
        <Avatar
          size="medium"
          imageId={this.props.user.photoId}
          name={this.props.user.lastName}
          source={{ uri: this.props.user.photoUrl }}
          accessibilityLabel={I18n.t("header|label|myAvatar")}
        />
        <Text style={styles.label}>{I18n.t("rides|label|myRides")}</Text>
      </View>
    );
  };

  private renderBackButton = () => (
    <TouchableOpacity onPress={this.goBack} style={styles.backButtonContainer}>
      <Icons.ChevronLeft color={colors.defaultText} width={16} height={16} />
    </TouchableOpacity>
  );

  private renderSecondaryBar = () => {
    if (this.isPathActive(AuthRouteNames.Map)) {
      return (
        <View style={styles.sideBarContainer}>
          <View>{this.renderMapInfoMenuPopup()}</View>
        </View>
      );
    }
    return <View style={styles.sideBarContainer}>{this.renderUserSettingsMenuPopup()}</View>;
  };

  private renderCmrModal = () => (
    <ModalComponent
      isVisible={this.state.isCmrVisible}
      onClose={this.toggleCmrModal}
      headerText={I18n.t("header|label|E-Cmr")}>
      {this.renderCmrView()}
    </ModalComponent>
  );

  private renderCmrView = () => {
    const ride = this.getRide();
    if (!ride) {
      return null;
    }

    if (!isMobileApp) {
      return <WebView source={{ uri: ride.cmrDocumentPath }} style={styles.cmrViewContainer} />;
    }

    return <Pdf source={{ uri: ride.cmrDocumentPath }} style={styles.cmrViewContainer} scale={1} />;
  };

  private renderMapInfoMenuPopup = () => {
    return (
      <Menu>
        <MenuTrigger customStyles={{ triggerWrapper: styles.triggerWrapper }}>
          <Icons.MorePoints color={colors.defaultText} />
        </MenuTrigger>
        <MenuOptions
          customStyles={{
            optionsContainer: styles.optionsContainer,
          }}>
          <MenuOption
            onSelect={this.toggleCmrModal}
            customStyles={{
              optionWrapper: styles.optionWrapper,
            }}>
            <Icons.Document color={colors.defaultText} />
            <Text style={styles.option}>{I18n.t("header|label|openE-Cmr")}</Text>
          </MenuOption>
        </MenuOptions>
      </Menu>
    );
  };

  private renderUserSettingsMenuPopup = () => {
    return (
      <Menu>
        <MenuTrigger customStyles={{ triggerWrapper: styles.triggerWrapper }}>
          <Icons.MorePoints color={colors.defaultText} />
        </MenuTrigger>
        <MenuOptions
          customStyles={{
            optionsContainer: styles.optionsContainer,
          }}>
          <MenuOption
            onSelect={this.logout}
            customStyles={{
              optionWrapper: styles.userSettingsOptionWrapper,
            }}>
            <Text style={styles.option}>{I18n.t("header|label|logout")}</Text>
          </MenuOption>
        </MenuOptions>
      </Menu>
    );
  };

  private logout = async () => {
    const PostUser = new Post("user");
    await PostUser.request("logout");
  };

  private toggleCmrModal = () => {
    this.setState({ isCmrVisible: !this.state.isCmrVisible });
  };

  private goBack = () => {
    this.props.history.goBack();
  };

  private getRide = () => {
    if (!this.props.ride) {
      return;
    }
    return this.props.ride;
  };

  private getRideIndex = () => {
    return this.props.match.params.rideIndex;
  };
}

const mapStateToProps = (state: IReduxStoreState) => ({
  user: state.user.data!,
});

export default connect(mapStateToProps)(withRouter(Header));

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: size.l,
    paddingVertical: isMobileApp ? size.m : size.xs,
    alignItems: "center",
    backgroundColor: colors.background,
    ...shadow,
  },
  modalContainer: {
    flexGrow: 1,
  },
  cmrViewContainer: {
    flexGrow: 1,
  },
  sideBarContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  label: {
    marginLeft: size.m,
    ...fonts.style.SubTitle,
  },
  option: {
    marginLeft: size.m,
    ...fonts.style.BodyRegular,
  },
  backButtonContainer: {
    alignItems: "center",
  },
  optionsContainer: { flex: 1, padding: size.m, justifyContent: "center" },
  optionWrapper: { flexDirection: "row", justifyContent: "space-between", padding: size.m },
  userSettingsOptionWrapper: {
    alignItems: "center",
    justifyContent: "center",
    margin: size.s,
  },
  triggerWrapper: { height: size.xl, width: size.xl, justifyContent: "center", alignItems: "center" },
});
