import reactotronReactNative from "reactotron-react-native";
import { reactotronRedux } from "reactotron-redux";
import AsyncStorage from "src/Config/AsyncStorage";
import { isMobileApp } from "src/Themes/variables";
import { AppReactotronReactNative } from "src/types";

const config = { name: "trucknet.tracker", port: 9095 };

class AppReactotron {
  public reactotronReactNative: AppReactotronReactNative;
  constructor() {
    let reactotron = reactotronReactNative;
    const { setAsyncStorageHandler } = reactotronReactNative;
    if (setAsyncStorageHandler) {
      reactotron = setAsyncStorageHandler(AsyncStorage);
    }

    this.reactotronReactNative = isMobileApp
      ? reactotron
          .configure(config)
          .useReactNative()
          .use(reactotronRedux())
          .connect()
      : reactotron
          .configure(config)
          .use(reactotronRedux())
          .connect();
  }
}

export default AppReactotron;
