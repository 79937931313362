import * as React from "react";
import { StyleSheet, Text, View, ViewStyle } from "react-native";
import Modal from "react-native-modal";
import { SafeAreaView } from "react-native-safe-area-context";
import { TransparentButton } from "react-native-web-uikit";
import Cross from "src/Images/Cross";
import colors from "src/Themes/colors";
import fonts from "src/Themes/fonts";
import { shadow, size } from "src/Themes/variables";

interface IProps {
  isVisible: boolean;
  children: JSX.Element | (JSX.Element | null)[] | null;
  onClose?(): void;
  headerText?: string;
  styles?: ViewStyle;
}

const ModalComponent = (props: IProps) => {
  return (
    <Modal style={[styles.modalContainer, props.styles]} isVisible={props.isVisible}>
      <SafeAreaView style={styles.container}>
        {props.onClose ? (
          <View style={styles.header}>
            <TransparentButton onPress={props.onClose}>
              <Cross color={colors.defaultText} />
            </TransparentButton>
            <Text style={styles.headerText}>{props.headerText}</Text>
          </View>
        ) : null}
        {props.children}
      </SafeAreaView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    justifyContent: "center",
    backgroundColor: colors.disable,
  },
  modalContainer: {
    padding: 0,
    margin: 0,
    backgroundColor: colors.background,
  },
  header: {
    flexDirection: "row",
    backgroundColor: colors.containerBackground,
    paddingHorizontal: size.m,
    paddingVertical: size.s,
    alignItems: "center",
    ...shadow,
  },
  headerText: {
    marginHorizontal: size.m,
    ...fonts.style.SubTitle,
    color: colors.defaultText,
  },
});

export default ModalComponent;
