import * as React from "react";
import { StyleSheet } from "react-native";
import RidesListContainer from "src/Features/RidesList/RidesListContainer";
import StoreLoader, { IResponse, loadIndicatorsTypes } from "src/StoreLoader";
import { size } from "src/Themes/variables";

interface IProps {}

interface IState {
  page: number;
}

class RidesLoader extends React.PureComponent<IProps, IState> {
  public state: IState = {
    page: 0,
  };
  private rides: IResponse | undefined;

  public render() {
    return (
      <StoreLoader
        type={loadIndicatorsTypes.cardsPlaceholder}
        cards={3}
        params={{
          page: this.state.page,
        }}
        stateName="rides"
        renderData={this.renderRides}
        loadIndicatorStyles={styles.placeholderContainer}
      />
    );
  }

  private showNextRidesPage = () => {
    this.setState({ page: this.state.page + 1 });
  };

  private refreshRides = () => {
    const rides = this.rides as IResponse;
    this.setState({ page: 0 }, rides.get);
  };

  private renderRides = (rides: IResponse) => {
    this.rides = rides;
    return <RidesListContainer onEndReached={this.showNextRidesPage} onRefresh={this.refreshRides} />;
  };
}

const styles = StyleSheet.create({
  placeholderContainer: { paddingHorizontal: size.l },
});

export default RidesLoader;
