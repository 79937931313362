import * as React from "react";
import { StyleSheet, Text, View } from "react-native";
import Dot from "src/Features/Common/Dot";
import { IRideDetails, setRideDetails } from "src/Helpers/ridesHelpers";
import { dateToEtaFormat } from "src/Helpers/timeFormatHelpers";
import I18n from "src/I18n";
import colors, { getTransparentColor } from "src/Themes/colors";
import fonts from "src/Themes/fonts";
import { borderRadius, size } from "src/Themes/variables";
import { ICommute } from "src/types";

interface IProps {
  ride: ICommute;
}

interface IState {
  isDocumentModalVisible: boolean;
}
class RideHeader extends React.PureComponent<IProps, IState> {
  public state = {
    isDocumentModalVisible: false,
  };

  public render() {
    const { ride } = this.props;
    const rideDetails: IRideDetails = setRideDetails(ride.deliveryStatus);
    return (
      <View
        style={[
          styles.container,
          {
            backgroundColor: getTransparentColor(rideDetails.color),
          },
        ]}>
        <View style={styles.textContainer}>
          <Dot color={rideDetails.color} size={6} />
          <Text numberOfLines={2} style={[styles.headerText, { color: rideDetails.color }]}>
            <Text>{`${rideDetails.title}  ${I18n.t("ride|header|ETA")}: ${dateToEtaFormat(ride.etaDateTime)}`}</Text>
          </Text>
        </View>
      </View>
    );
  }

}

const styles = StyleSheet.create({
  container: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    padding: size.l,
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius,
  },
  textContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  headerText: {
    color: colors.themeColor,
    textTransform: "uppercase",
    ...fonts.style.Subheading,
    marginHorizontal: size.m,
  },
});

export default RideHeader;
