import { GeolocationReturnType } from "react-native";
import I18n from "src/I18n";
const webGeolocation = navigator.geolocation;

export const getCurrentPosition = (options: { enableHighAccuracy: boolean; timeout: number; maximumAge: number }) =>
  new Promise<GeolocationReturnType>(async (resolve, reject) => {
    webGeolocation.getCurrentPosition(
      (position: GeolocationReturnType) => {
        resolve(position);
      },
      (error) => {
        reject(error);
      },
      options,
    );
  });

export const getAccuratePositionWithFallback = async (): Promise<GeolocationReturnType> => {
  let position;
  try {
    position = await getCurrentPosition({
      enableHighAccuracy: false,
      timeout: 6000,
      maximumAge: 60 * 1000,
    });
  } catch (e) {
    throw new Error(
      // prettier-ignore
      `
      ${I18n.t("toast|text|please,AllowThisApplicationToGetTheDevicesLocation")}.
      ${I18n.t("toast|text|weWillUseThisLocationOnlyWhenYouChangeDeliveryStatusToSaveThisInformationInTheE-cmrDocument")}.
      `,
    );
  }
  return position;
};
