import * as React from "react";
import StoreLoader, { IResponse, loadIndicatorsTypes } from "src/StoreLoader";
import { ICargoTypes, ICommute } from "src/types";
import CargoComponent from "./CargoComponent";

interface IProps {
  ride: ICommute;
}

interface IState {
  destinationName: string;
}

class CargoContainer extends React.PureComponent<IProps, IState> {
  public render() {
    return (
      <StoreLoader
        type={loadIndicatorsTypes.paragraphPlaceholder}
        lines={3}
        stateName="cargoTypes"
        renderData={this.renderCargo}
      />
    );
  }

  private renderCargo = (cargoTypes: IResponse) => {
    return <CargoComponent ride={this.props.ride} cargoTypes={cargoTypes.data as ICargoTypes} />;
  };
}

export default CargoContainer;
