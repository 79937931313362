import * as React from "react";
import { StyleSheet, View } from "react-native";
import { RideProgressCard } from "react-native-web-uikit";
import { getRideProgressProps } from "src/Helpers/ridesHelpers";
import { isWeb, size } from "src/Themes/variables";
import { ICommute } from "src/types";

interface IProps {
  ride: ICommute;
}

class RideProgressCardComponent extends React.PureComponent<IProps> {
  public render() {
    return (
      <View style={styles.container}>
        <RideProgressCard
          origin={getRideProgressProps(this.props.ride, "origin")}
          destination={getRideProgressProps(this.props.ride, "destination")}
        />
      </View>
    );
  }
}

export default RideProgressCardComponent;

const styles = StyleSheet.create({
  container: isWeb
    ? { flexGrow: 1, paddingVertical: size.l, marginHorizontal: size.l, height: 200 }
    : {
        flexShrink: 1,
        flexBasis: 0,
        paddingVertical: size.l,
        marginHorizontal: size.l,
        width: "70%",
        minHeight: 184,
      },
});
