import I18n from "src/I18n";
import { ICargoType, ICargoTypes } from "src/types";

export const getCargoTypeNameById = (cargoTypes: ICargoTypes, cargoTypeId?: string) => {
  const cargoType = cargoTypes.find((item: ICargoType) => item._id === cargoTypeId);
  if (cargoType) {
    return cargoType.name;
  }
  return I18n.t("ride|cargo|noData");
};
