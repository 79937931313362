import * as React from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { Icons, TransparentButton } from "react-native-web-uikit";
import MapView from "src/Features/Common/MapView";
import CargoComponent from "src/Features/Common/RideCardComponent/CargoComponent";
import DriversComponent from "src/Features/Common/RideCardComponent/DriversComponent";
import RideProgressCardComponent from "src/Features/Common/RideCardComponent/RideProgressCardComponent";
import { setRideDetails } from "src/Helpers/ridesHelpers";
import colors from "src/Themes/colors";
import { shadow, size } from "src/Themes/variables";
import { ICommute, ITruckRoute } from "src/types";
import CompanyCard from "./CompanyCard";
import DriverCard from "./DriverCard";

interface IProps {
  ride: ICommute;
  actualVehiclesRoutes?: ITruckRoute[];
}

interface IState {
  isCardOpen: Boolean;
}

class RideCardMap extends React.PureComponent<IProps, IState> {
  public state: IState = {
    isCardOpen: false,
  };

  public render() {
    const { ride, actualVehiclesRoutes } = this.props;
    return (
      <View style={styles.container}>
        <MapView actualVehiclesRoutes={actualVehiclesRoutes} ride={ride} />
        <ScrollView showsVerticalScrollIndicator={false} style={styles.scrollViewContainer}>
          {this.renderChangeCardPositionButton()}
          <DriverCard ride={ride} />
          {this.renderExtendedDriverCardPart()}
        </ScrollView>
      </View>
    );
  }

  private renderExtendedDriverCardPart = () => {
    const { ride } = this.props;
    if (!this.state.isCardOpen) {
      return;
    }
    return (
      <React.Fragment>
        <CompanyCard ride={ride} />
        <RideProgressCardComponent ride={this.props.ride} />
        <CargoComponent ride={ride} />
        <DriversComponent ride={ride} />
      </React.Fragment>
    );
  };

  private openCard = () => {
    this.setState({ isCardOpen: true });
  };

  private closeCard = () => {
    this.setState({ isCardOpen: false });
  };

  private renderChangeCardPositionButton = () => {
    const rideDetails = setRideDetails(this.props.ride.deliveryStatus);
    if (this.state.isCardOpen) {
      return (
        <View style={styles.chevronContainer}>
          <TransparentButton onPress={this.closeCard} style={{ paddingVertical: size.m }}>
            <Icons.ChevronDown color={rideDetails.color} />
          </TransparentButton>
        </View>
      );
    }
    return (
      <View style={styles.chevronContainer}>
        <TransparentButton onPress={this.openCard} style={{ paddingVertical: size.m }}>
          <Icons.ChevronUp color={rideDetails.color} />
        </TransparentButton>
      </View>
    );
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-end",
    backgroundColor: colors.background,
  },
  scrollViewContainer: {
    flexGrow: 0,
  },
  chevronContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    height: size.xxl,
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
    zIndex: size.xxl,
  },
  cardContainer: {
    backgroundColor: colors.background,
    ...shadow,
  },
  buttonHeight: {
    height: size.m,
  },
});

export default RideCardMap;
