import * as React from "react";
import { StyleSheet, View } from "react-native";
import { size } from "src/Themes/variables";

interface IProps {
  size: number;
  color?: string;
  hollowSize: number;
}

class Dot extends React.PureComponent<IProps> {
  public static defaultProps = {
    hollowSize: 0,
  };
  public render() {
    const { color } = this.props;
    const dotSize = this.props.size;
    const hollowSize = this.props.hollowSize;
    return (
      <View
        style={[
          styles.container,
          {
            width: dotSize,
            height: dotSize,
            borderRadius: dotSize / 2,
            borderWidth: dotSize / 2 - hollowSize / 2,
            borderColor: color,
          },
        ]}
      />
    );
  }
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    marginHorizontal: size.s,
  },
});

export default Dot;
