import { Dimensions, Platform } from "react-native";
import colors from "src/Themes/colors";

export const navigationBarHeight = 50;

export const headerHeight = 50;
export const borderRadius = 3;
export const borderWidth = 1;

export const isMobileApp = Platform.OS === "android" || Platform.OS === "ios";
export const isWeb = !isMobileApp;
export const isIos = Platform.OS === "ios";
export const shadow =
  isIos || isWeb
    ? {
        shadowColor: colors.shadow,
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.8,
        shadowRadius: 4,
        zIndex: 100,
      }
    : {
        elevation: 4,
        zIndex: 100,
      };

const sizeBase = isMobileApp ? 2 : 2.3;

const getSizePoweredSize = (pow: number) => Math.floor(Math.pow(sizeBase, pow));
export const size = {
  xs: sizeBase,
  s: getSizePoweredSize(2),
  m: getSizePoweredSize(3),
  l: getSizePoweredSize(4),
  xl: getSizePoweredSize(5),
  xxl: getSizePoweredSize(6),
};

let screenWidth = Dimensions.get("screen").width;
let screenHeight = Dimensions.get("screen").height;

Dimensions.addEventListener("change", (dimensions) => {
  screenWidth = dimensions.screen.width;
  screenHeight = dimensions.screen.height;
});

export const visibleScreenHeight = screenHeight - navigationBarHeight;
export { screenWidth, screenHeight };
