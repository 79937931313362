import * as React from "react";
import { StyleSheet, Text, View } from "react-native";
import { colorTheme, GradientButton } from "react-native-web-uikit";
import { RouteComponentProps, withRouter } from "react-router-native";
import { getDeliveryStatusRoute } from "src/Router";
import { size } from "src/Themes/variables";
import { ICommute, ICustomerRole } from "src/types";

interface IProps extends RouteComponentProps {
  ride: ICommute;
  label: string;
  customerRole: ICustomerRole;
}

class ChangeStatusButton extends React.PureComponent<IProps> {
  public render() {
    return (
      <View style={styles.container}>
        <GradientButton onPress={this.navigateToDeliveryStatusRoute}>
          <Text style={styles.text}>{this.props.label}</Text>
        </GradientButton>
      </View>
    );
  }

  private navigateToDeliveryStatusRoute = () => this.props.history.push(getDeliveryStatusRoute(this.props.ride._id));
}

export default withRouter(ChangeStatusButton);

const styles = StyleSheet.create({
  container: { padding: size.l },
  text: { color: colorTheme.light.palette.white },
});
