import * as React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import AgreementsModalContainer from "src/Features/Agreements/AgreementsModalContainer";
import RidesList from "src/Features/RidesList";
import { getMainRoute, getResetPasswordRoute } from "src/Router";
import { IReduxStore } from "src/types";

type Props = {
  hasPassword: boolean;
};

const MainPage = ({ hasPassword }: Props) => {
  const history = useHistory();
  React.useEffect(() => {
    if (!hasPassword) {
      history.push(getResetPasswordRoute());
      return;
    }
    history.push(getMainRoute());
    return;
  }, [hasPassword]);
  return (
    <React.Fragment>
      <RidesList />
      <AgreementsModalContainer />
    </React.Fragment>
  );
};

const mapStateToProps = ({ user }: IReduxStore) => ({
  hasPassword: user.data!.hasPassword,
});

export default connect(mapStateToProps)(MainPage);
