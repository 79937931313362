import * as React from "react";
import { ScrollView, StyleSheet } from "react-native";
import ShimmerPlaceHolder from "react-native-shimmer-placeholder";
import { size } from "src/Themes/variables";

interface IProps {
  cards?: number;
}

class CardsPlaceholder extends React.PureComponent<IProps> {
  public render() {
    return (
      <ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
        {this.renderPlaceholderLines()}
      </ScrollView>
    );
  }

  private renderPlaceholderLines = () => {
    const placeholderCards = [];
    const cards = this.props.cards || 2;
    let i;
    for (i = 0; i < cards; i++) {
      placeholderCards.push(<ShimmerPlaceHolder style={styles.card} key={i} />);
    }
    return placeholderCards;
  };
}

export default CardsPlaceholder;

const styles = StyleSheet.create({
  container: { width: "100%" },
  card: {
    width: "100%",
    height: 250,
    marginTop: size.l,
  },
});
