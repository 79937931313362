import * as React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import { Form, Icons } from "react-native-web-uikit";
import { validateEmail } from "src/Helpers/validateHelpers";
import I18n from "src/I18n";
import { Post } from "src/Redux";

import { RouteComponentProps, withRouter } from "react-router-native";
import KeyboardAvoidingViewWrapper from "src/Features/Common/KeyboardAvoidingViewWrapper";
import Notification from "src/Features/Common/Notification";
import Subscriber from "src/Subscriber";
import colors from "src/Themes/colors";
import { size } from "src/Themes/variables";

interface IForm extends RouteComponentProps {}

class PasswordForm extends React.PureComponent<IForm> {
  public render() {
    return (
      <KeyboardAvoidingViewWrapper style={styles.container}>
        <TouchableOpacity onPress={this.goBack} style={styles.backButtonContainer}>
          <Icons.ChevronLeft color={colors.defaultText} width={16} height={16} />
          <Text style={styles.backButtonText}>{I18n.t("forgotPassword|text|back")}</Text>
        </TouchableOpacity>
        <Form
          fields={{
            email: {
              label: I18n.t("forms|fields|email"),
              validate: validateEmail,
            },
          }}
          submitLabel={I18n.t("password|button|resetPassword").toUpperCase()}
          handleSubmit={this.handleSubmit}>
          <Notification timeout={10000} onHideNotification={this.goBack} isFormNotification />
        </Form>
      </KeyboardAvoidingViewWrapper>
    );
  }

  private goBack = () => this.props.history.goBack();

  private handleSubmit = async (fields: { email: { value: string; isValid: boolean } }) => {
    const postUser = new Post("user");
    await postUser.request("forgotPassword", { email: fields.email.value });
    Subscriber.showNotification({
      text: I18n.t("toast|text|instructionsWasSendOnEmail"),
    });
  };
}

export default withRouter(PasswordForm);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    justifyContent: "center",
  },
  backButtonContainer: {
    height: size.xl,
    width: size.xxl,
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: "8%",
    marginTop: size.m,
    flexDirection: "row",
  },
  backButtonText: {
    color: colors.defaultText,
  },
});
