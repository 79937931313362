import I18n from "src/I18n";
import colors from "src/Themes/colors";
import { ICommute } from "src/types";

export interface IRideDetails {
  color: string;
  title: string;
}

// tslint:disable-next-line: no-object-literal-type-assertion
export const RIDE_STATUSES = {
  started: "STARTED",
  arrivedAtPickUp: "ARRIVED_AT_PICK_UP",
  onBreak: "ON_BREAK",
  problem: "PROBLEM",
  departedFromPickUp: "DEPARTED_FROM_PICK_UP",
  arrivedAtDestination: "ARRIVED_AT_DESTINATION",
  receivedCargo: "RECEIVED_CARGO",
  finished: "FINISHED",
  notStarted: "NOT_STARTED",
} as const;

type DeliveryStatus = { status: string } | undefined;

export const setRideDetails = (deliveryStatus: DeliveryStatus) => {
  const status = deliveryStatus && deliveryStatus.status;
  switch (status) {
    case RIDE_STATUSES.started:
      return { color: colors.themeColor, title: I18n.t("common|rideStatuses|onTheWay") };
    case RIDE_STATUSES.onBreak:
      return { color: colors.palette.dodgerBlue, title: I18n.t("common|rideStatuses|onABreak") };
    case RIDE_STATUSES.problem:
      return { color: colors.palette.torchRed, title: I18n.t("common|rideStatuses|problem") };
    case RIDE_STATUSES.receivedCargo:
      return { color: colors.palette.ashLight, title: I18n.t("common|rideStatuses|driverReceivedCargo") };
    case RIDE_STATUSES.arrivedAtPickUp:
      return { color: colors.palette.ashDark, title: I18n.t("common|rideStatuses|driverArrivedAtPickUp") };
    case RIDE_STATUSES.departedFromPickUp:
      return { color: colors.themeColor, title: I18n.t("common|rideStatuses|cargoDeparted") };
    case RIDE_STATUSES.arrivedAtDestination:
      return { color: colors.themeColor, title: I18n.t("common|rideStatuses|cargoArrived") };
    case RIDE_STATUSES.finished:
      return { color: colors.palette.gray, title: I18n.t("common|rideStatuses|finished") };
    default:
      return { color: colors.palette.elephant, title: I18n.t("common|rideStatuses|notStarted") };
  }
};

export const isCargoSent = (
  deliveryStatus: DeliveryStatus,
  deliveryStatusHistory: ICommute["deliveryStatusHistory"],
): boolean => {
  const status = deliveryStatus && deliveryStatus.status;
  const statusHistory = deliveryStatusHistory;
  if (status && (status === RIDE_STATUSES.onBreak || status === RIDE_STATUSES.problem)) {
    statusHistory.pop();
    const previousStatus = statusHistory[statusHistory.length - 1];
    return isCargoSent(previousStatus, statusHistory);
  }
  switch (status) {
    case RIDE_STATUSES.started:
      return false;
    case RIDE_STATUSES.arrivedAtPickUp:
      return false;
    case RIDE_STATUSES.receivedCargo:
      return true;
    case RIDE_STATUSES.departedFromPickUp:
      return true;
    case RIDE_STATUSES.arrivedAtDestination:
      return true;
    case RIDE_STATUSES.finished:
      return true;
    default:
      return false;
  }
};

export const isCargoReceived = (deliveryStatus: DeliveryStatus): boolean => {
  const status = deliveryStatus && deliveryStatus.status;

  return status === RIDE_STATUSES.finished;
};

export const getRideProgressProps = (ride: ICommute, type: "origin" | "destination") => {
  const time = type === "origin" ? ride.startDatetime : ride.endDatetime;
  return {
    date: time,
    city: type === "origin" ? ride.originName : ride.destinationName,
    address: type === "origin" ? ride.originAddress : ride.destinationAddress,
  };
};
