import AppEnvConfig from "./AppEnvConfig";

const appEnvConfig = new AppEnvConfig();

export const { ENV, BASE_URL, API_VERSION, PTV, REGION } = {
  ENV: appEnvConfig.env,
  BASE_URL: appEnvConfig.baseUrl,
  API_VERSION: appEnvConfig.apiVersion,
  PTV: appEnvConfig.ptv,
  REGION: appEnvConfig.region,
};
