import * as React from "react";
import { ViewStyle } from "react-native";
import Subscriber, { NotificationEvent } from "src/Subscriber";
import colors from "src/Themes/colors";
import FormNotification from "./FormNotification";
import GeneralNotification from "./GeneralNotification";

interface IProps {
  timeout?: number;
  notificationStyles: {
    color: string;
    textColor: string;
    containerStyles?: ViewStyle;
  };
  onHideNotification?(): void;
  isFormNotification?: boolean;
}

interface IState {
  isVisible: boolean;
  notification: { text: string };
}

class Notification extends React.PureComponent<IProps, IState> {
  private timeoutId?: NodeJS.Timer;
  public static defaultProps = {
    notificationStyles: {
      color: colors.palette.lightGreen,
      textColor: colors.themeColor,
    },
  };

  public initialNotificationEvent = {
    text: "",
  };
  public state = {
    notification: this.initialNotificationEvent,
    isVisible: false,
  };
  public componentDidMount = () => {
    Subscriber.subscribeShowNotification(this.showNotification);
  };
  public render() {
    if (!this.state.isVisible) {
      return null;
    }

    return this.props.isFormNotification ? (
      <FormNotification
        hideNotification={this.hideNotification}
        notificationStyles={this.props.notificationStyles}
        text={this.state.notification.text}
      />
    ) : (
      <GeneralNotification hideNotification={this.hideNotification} text={this.state.notification.text} />
    );
  }

  private showNotification = (e: NotificationEvent) => {
    clearTimeout(Number(this.timeoutId));
    this.setState({ notification: e, isVisible: true });
    if (!this.props.timeout) {
      return;
    }
    this.timeoutId = setTimeout(this.hideNotification, this.props.timeout);
  };

  private hideNotification = () => {
    this.setState({ notification: this.initialNotificationEvent, isVisible: false }, this.props.onHideNotification);
  };
}

export default Notification;
