import { Api, ApiRequestNames, ApiRequestParams } from "./Api";

export const makeApiRequest = async (
  requestName: ApiRequestNames,
  id?: string,
  params: ApiRequestParams = {},
): Promise<unknown> => {
  const api = new Api(id, params);
  const res = await api[requestName]();
  return res;
};
