import * as React from "react";
import { AppRegistry } from "react-native";
import App from "./App";

class WebApp extends React.Component {
  public render() {
    return <App />;
  }
}

AppRegistry.registerComponent("trucknet.tracker", () => WebApp);
AppRegistry.runApplication("trucknet.tracker", { rootTag: document.getElementById("root") });
