import { AnyAction } from "redux";
import {
  ICargoTypesState,
  ICommutesObjectsState,
  ICommuteState,
  ICommutesWithActualVehiclesRoutesState,
  ICompaniesState,
  IUsersObjectsState,
  IUserState,
  IVehiclesState,
} from "src/types";

export type ReduxStore = {
  rides: ICommuteState;
  commutesWithActualVehiclesRoutes: ICommutesWithActualVehiclesRoutesState;
  cargoTypes: ICargoTypesState;
  companies: ICompaniesState;
  user: IUserState;
  users: IUsersObjectsState;
  commutesObjects: ICommutesObjectsState;
  vehicles: IVehiclesState;
};

export type StoreStateNames = keyof ReduxStore;

export const ActionTypes = {
  DATA_FETCHING_START: "DATA_FETCHING_START",
  DATA_FETCHING_SUCCEED: "DATA_FETCHING_SUCCEED",
  DATA_FETCHING_FAILED: "DATA_FETCHING_FAILED",
  LOGOUT: "LOGOUT",
};

const { DATA_FETCHING_SUCCEED, DATA_FETCHING_FAILED, DATA_FETCHING_START } = ActionTypes;

export const storeStateTypes = {
  companies: "companies" as const,
  commutesWithActualVehiclesRoutes: "commutesWithActualVehiclesRoutes" as const,
  rides: "rides" as const,
  commutesObjects: "commutesObjects" as const,
  cargoTypes: "cargoTypes" as const,
  user: "user" as const,
  users: "users" as const,
  vehicles: "vehicles" as const,
  objectIdsReducers: ["companies", "commutesObjects", "commutesWithActualVehiclesRoutes", "users", "vehicles"],
};

const INITIAL_COLLECTION_STATE = {
  data: undefined,
  isError: false,
  isFetching: false,
  error: undefined,
};

const INITIAL_OBJECTS_IDS_STATE = {};

export const INITIAL_OBJECT_STATE = {
  data: undefined,
  isError: false,
  isFetching: false,
  error: undefined,
};

export const INITIAL_STATE = {
  rides: INITIAL_COLLECTION_STATE,
  cargoTypes: INITIAL_COLLECTION_STATE,
  companies: INITIAL_OBJECTS_IDS_STATE,
  commutesWithActualVehiclesRoutes: INITIAL_OBJECTS_IDS_STATE,
  user: INITIAL_COLLECTION_STATE,
  users: INITIAL_OBJECTS_IDS_STATE,
  commutesObjects: INITIAL_OBJECTS_IDS_STATE,
  vehicles: INITIAL_OBJECTS_IDS_STATE,
};

export const CollectionReducer = (stateName: StoreStateNames) => (state = INITIAL_STATE, action: AnyAction) => {
  const currentState = state[stateName] || state;
  if (action.stateName === stateName) {
    switch (action.type) {
      case DATA_FETCHING_START:
        return { ...currentState, isFetching: true, isError: false };
      case DATA_FETCHING_SUCCEED:
        return { ...currentState, isFetching: false, isError: false, data: action.payload };
      case DATA_FETCHING_FAILED:
        return { ...currentState, isFetching: false, isError: true, error: action.payload };
      default:
        return currentState;
    }
  }
  return currentState;
};

export const ObjectIdsReducer = (stateName: StoreStateNames) => (state = INITIAL_STATE, action: AnyAction) => {
  const currentState = state[stateName] || state;
  if (action.stateName === stateName) {
    switch (action.type) {
      case DATA_FETCHING_START:
        return { ...currentState, [action.id]: { isFetching: true, isError: false } };
      case DATA_FETCHING_SUCCEED:
        return {
          ...currentState,
          [action.id]: { isFetching: false, isError: false, data: action.payload },
        };
      case DATA_FETCHING_FAILED:
        return {
          ...currentState,
          [action.id]: { isFetching: false, isError: true, error: action.payload },
        };
      default:
        return currentState;
    }
  }
  return currentState;
};
