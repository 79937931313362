import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-native";
import RideCardContainer from "src/Features/Map/RideCardContainer";

class MapContainer extends React.PureComponent<RouteComponentProps> {
  public render() {
    return <RideCardContainer />;
  }
}

export default withRouter(MapContainer);
