import * as React from "react";
import { StyleSheet, View } from "react-native";
import DriverComponent from "src/Features/Common/DriverComponent";
import RideProperty from "src/Features/Common/RideCardComponent/RideProperty";
import VehiclePlate from "src/Features/Common/VehiclePlate";
import I18n from "src/I18n";
import colors from "src/Themes/colors";
import { borderWidth, size } from "src/Themes/variables";
import { ICommute } from "src/types";
interface IProps {
  ride: ICommute;
}

class DriversComponent extends React.PureComponent<IProps> {
  public render() {
    if (!this.props.ride.driversIds?.length) {
      return null;
    }
    return this.props.ride.driversIds.map((driverId) => {
      return (
        <View key={driverId} style={styles.container}>
          <RideProperty label={I18n.t("ride|driver|driverName")}>
            <DriverComponent driverId={driverId} />
          </RideProperty>
          <RideProperty
            label={
              this.props.ride.vehiclesIds.length > 1
                ? I18n.t("ride|driver|licensePlates")
                : I18n.t("ride|driver|licensePlate")
            }>
            <>
              {this.props.ride.vehiclesIds.map((vehicleId: string) => (
                <VehiclePlate rideId={this.props.ride._id} vehicleId={vehicleId} key={vehicleId} />
              ))}
            </>
          </RideProperty>
        </View>
      );
    });
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: size.m,
    justifyContent: "space-between",
    flexWrap: "wrap",
    padding: size.m,
    borderTopWidth: borderWidth,
    borderTopColor: colors.secondaryText,
  },
});

export default DriversComponent;
