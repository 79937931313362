import * as React from "react";
import { StyleSheet, View } from "react-native";
import MapPlaceholderIcon from "src/Images/MapPlaceholderIcon";
import colors from "src/Themes/colors";

interface IProps {
  lines?: number;
}

class MapPlaceholder extends React.PureComponent<IProps> {
  public render() {
    return (
      <React.Fragment>
        <View style={styles.container}>
          <MapPlaceholderIcon color={colors.defaultText} size={150} />
        </View>
      </React.Fragment>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: 300,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default MapPlaceholder;
