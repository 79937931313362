import { createSelector } from "reselect";
import { IUserState } from "src/types";

const agreementsToUpdateSelector = (state: { user: IUserState }) => {
  return state.user.data?.agreementsToUpdate;
};
const termsAndConditionsSelector = (state: { user: IUserState }) => {
  return state.user.data?.agreementsToUpdate?.termsAndConditions;
};

export const getAgreementUrl = createSelector(agreementsToUpdateSelector, (agreementsToUpdate) => {
  if (agreementsToUpdate?.termsAndConditions) {
    return agreementsToUpdate.termsAndConditions;
  }
  return agreementsToUpdate?.privacyPolicy;
});

export const getTermsAndConditionsAgreementUrl = createSelector(
  termsAndConditionsSelector,
  (termsAndConditions) => termsAndConditions,
);
