import * as React from "react";
import { ProgressBar } from "react-native-web-uikit";

class ProgressBarComponent extends React.PureComponent {
  public render() {
    return <ProgressBar />;
  }
}

export default ProgressBarComponent;
