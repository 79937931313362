import * as React from "react";
import { ActivityIndicator, StyleSheet, Text, View } from "react-native";
import { GradientButton } from "react-native-web-uikit";
import WebView from "react-native-webview";
import { connect } from "react-redux";
import ModalComponent from "src/Features/Common/ModalComponent";
import { deviceCountryIsoCode } from "src/Helpers/countryHelper";
import I18n from "src/I18n";
import { languageCodes, locale } from "src/I18n/I18n";
import { Put } from "src/Redux";
import { getAgreementUrl, getTermsAndConditionsAgreementUrl } from "src/Selectors/agreementsSelector";
import { selectUserId } from "src/Selectors/userSelector";
import colors from "src/Themes/colors";
import { borderRadius, size } from "src/Themes/variables";
import { IReduxStore } from "src/types";

interface IProps {
  userId: string;
  agreementToUpdateUrl?: string;
  isAgreementsAcceptRequired: boolean;
  isTermsAndConditionsAcceptRequired: boolean;
}

class AgreementsModalContainer extends React.PureComponent<IProps> {
  private putUser = new Put("user");

  public render() {
    return (
      <ModalComponent isVisible={this.props.isAgreementsAcceptRequired}>
        <View style={styles.container}>
          {this.renderTopText()}
          {this.renderWebView()}
          {this.renderAcceptButton()}
        </View>
      </ModalComponent>
    );
  }

  private renderWebView = () => {
    const { agreementToUpdateUrl } = this.props;
    if (!agreementToUpdateUrl) {
      return <ActivityIndicator size={32} />;
    }
    return (
      <WebView
        automaticallyAdjustContentInsets
        source={{
          uri: agreementToUpdateUrl.replace("REPLACE_TO_CURRENT_LOCALE", this.getAgreementsLocale()),
        }}
        scalesPageToFit
        startInLoadingState
      />
    );
  };

  private getAgreementsLocale = () => {
    if (locale !== languageCodes.he) {
      return locale;
    }
    if (deviceCountryIsoCode === "IL") {
      return locale;
    }
    return languageCodes.en;
  };

  private acceptTermsAndConditions = async () => {
    await this.putUser.request("editUser", { agreementVersions: { termsAndConditions: true } }, this.props.userId);
  };

  private acceptPrivacyPolicy = async () => {
    await this.putUser.request(
      "editUser",
      {
        agreementVersions: { privacyPolicy: true, termsAndConditions: true },
        agreementsToUpdate: undefined,
      },
      this.props.userId,
    );
  };

  private renderAcceptButton = () => {
    return (
      <View style={styles.acceptButtonContainer}>
        <GradientButton
          onPress={this.handleAcceptButtonPress}
          label={I18n.t("agreements|button|accept").toUpperCase()}
          style={styles.button}
        />
      </View>
    );
  };
  private handleAcceptButtonPress = () => {
    if (this.props.isTermsAndConditionsAcceptRequired) {
      return this.acceptTermsAndConditions();
    }
    return this.acceptPrivacyPolicy();
  };

  private renderTopText = () => {
    return (
      <View style={styles.topTextContainer}>
        <Text style={styles.title}>
          {this.props.isTermsAndConditionsAcceptRequired
            ? I18n.t("agreements|title|termsAndConditions")
            : I18n.t("agreements|title|privacyPolicy")}
        </Text>
        <Text style={styles.subTitle}>{I18n.t("agreements|subTitle|pleaseReviewAgreementAndAcceptIt")}.</Text>
      </View>
    );
  };
}

const mapStateToProps = (state: IReduxStore) => ({
  userId: selectUserId(state),
  agreementToUpdateUrl: getAgreementUrl(state),
  isAgreementsAcceptRequired: Boolean(getAgreementUrl(state)),
  isTermsAndConditionsAcceptRequired: Boolean(getTermsAndConditionsAgreementUrl(state)),
});

export default connect(mapStateToProps)(AgreementsModalContainer);
const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: colors.subBackground,
  },
  webViewContainer: {
    flexGrow: 1,
  },
  topTextContainer: {
    height: 150,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.subBackground,
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    color: colors.defaultText,
    marginBottom: size.l,
  },
  subTitle: {
    color: colors.defaultText,
    marginBottom: size.m,
  },
  acceptButtonContainer: {
    height: 80,
    alignItems: "center",
    backgroundColor: colors.subBackground,
    borderBottomLeftRadius: borderRadius,
    borderBottomRightRadius: borderRadius,
    flexDirection: "row",
    justifyContent: "space-around",
  },
  button: { flexGrow: 0, paddingHorizontal: 8 },
});
