import * as React from "react";
import { KeyboardAvoidingView, Platform, StyleSheet, ViewStyle } from "react-native";

type Element = JSX.Element | null;

interface Props {
  children: Element[] | Element;
  style?: ViewStyle;
}

const KeyboardAvoidingViewWrapper = (props: Props) => {
  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={[styles.container, props.style]}>
      {props.children}
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: { flexGrow: 1 },
});

export default KeyboardAvoidingViewWrapper;
