import * as Leaflet from "leaflet";
import * as React from "react";
import { Map as LeafletMap, TileLayer, WMSTileLayer } from "react-leaflet";
import { ICommute, ITruckRoute } from "src/types";

// You will notably need to add its CSS to your page to render the map properly,
// and set the height of the <Map> container.
// https://react-leaflet.js.org/docs/en/setup.html
// tslint:disable-next-line:no-import-side-effect
import "leaflet/dist/leaflet.css";

import { Route } from "./components";
import { MAP_DEFAULT_BOUNDS, MAP_TILES_CONFIG } from "./config";

export type Title = React.ReactNode;

export type Point = [number, number];

type Polyline = Point[];

export type VehicleData = {
  position: Point;
  title?: Title;
};

export type RouteData = {
  polyline: Polyline;
  titles: {
    origin: Title;
    destination: Title;
  };
};

interface MapState {
  ride: ICommute;
  actualVehiclesRoutes?: ITruckRoute[];
}

interface Props extends MapState {
  className?: string;
}

type State = {
  bounds: [number, number][];
};

class MapView extends React.PureComponent<Props, State> {
  private bounds = this.getMapBounds() as Leaflet.LatLngBoundsExpression | undefined;
  public render() {
    const mapStyle = { flexGrow: 1 };

    return (
      <LeafletMap style={mapStyle} bounds={this.bounds} className={this.props.className}>
        {this.getTiles()}
        {this.props.actualVehiclesRoutes ? this.getVehiclesRoutes() : this.getRideRoute()}
      </LeafletMap>
    );
  }

  private getRidePolyline(): Point[] | undefined {
    return this.props.ride.directions?.coords.map((coord) => [coord.latitude, coord.longitude]);
  }

  private getVehiclesRoutesCoords() {
    return this.props.actualVehiclesRoutes?.map((routes) =>
      routes.coords.map((coord) => [coord.latitude, coord.longitude] as [number, number]),
    );
  }
  private getRideTitles = () => ({
    origin: this.props.ride.originName,
    destination: this.props.ride.destinationName,
  });

  private getMapBounds() {
    const ridePoints = this.getRidePolyline();
    const vehiclesPoints = this.getVehiclesRoutesCoords();

    if (!ridePoints && !vehiclesPoints) {
      return;
    }
    const bounds: Point[] = [];

    if (vehiclesPoints) {
      bounds.push(...vehiclesPoints[0]);
    } else {
      bounds.push(...ridePoints!);
    }

    if (bounds.length === 1) {
      return this.takeBoundsForOnePoint(bounds[0]);
    }

    if (this.invalidBounds(bounds)) {
      return MAP_DEFAULT_BOUNDS;
    }

    return bounds;
  }

  private takeBoundsForOnePoint(p: Point): Point[] {
    return [
      [p[0] + 1, p[1] + 1],
      [p[0] - 1, p[1] - 1],
    ];
  }

  private invalidBounds(bounds: Point[]) {
    return !bounds.length;
  }

  private getTiles = () => {
    if (!MAP_TILES_CONFIG.tile) {
      throw new Error(`Couldn't find config for TileLayer`);
    }
    return (
      <React.Fragment>
        <TileLayer {...MAP_TILES_CONFIG.tile} />
        {MAP_TILES_CONFIG.wmsTile ? <WMSTileLayer {...MAP_TILES_CONFIG.wmsTile} /> : null}
      </React.Fragment>
    );
  };

  private getRideRoute = () => {
    const polyline = this.getRidePolyline();
    const titles = this.getRideTitles();

    if (!polyline) {
      return null;
    }

    return <Route polylineType="ride" route={polyline} titles={titles} />;
  };

  private getVehiclesRoutes = () => {
    const vehiclesRoutes = this.getVehiclesRoutesCoords();
    if (!vehiclesRoutes) {
      return null;
    }

    return vehiclesRoutes.map((route, i) => {
      return <Route polylineType="truckRoute" route={route.reverse()} key={i} />;
    });
  };
}

export default MapView;
