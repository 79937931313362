import * as React from "react";

import leaflet, { Icon, IconOptions } from "leaflet";
import { Marker as LeafletMarker, Popup } from "react-leaflet";

type Props = {
  position: [number, number]; // [lat, lng],
  title?: React.ReactNode;
  icon?: IconOptions;
};

const getIcon = (icon: IconOptions) => leaflet.icon(icon);
export const Marker = ({ position, title, icon }: Props) => {
  const markerIcon: { icon: Icon } | {} = icon ? { icon: getIcon(icon) } : {};
  return (
    <LeafletMarker position={position} {...markerIcon}>
      <React.Fragment>{title ? <Popup>{title}</Popup> : null}</React.Fragment>
    </LeafletMarker>
  );
};
