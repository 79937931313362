import * as React from "react";
import { StyleSheet, Text, View } from "react-native";
import RideProperty from "src/Features/Common/RideCardComponent/RideProperty";
import I18n from "src/I18n";
import { getCargoTypeNameById } from "src/Selectors/cargoTypesSelector";
import colors from "src/Themes/colors";
import fonts from "src/Themes/fonts";
import { borderWidth, size } from "src/Themes/variables";
import { ICargoTypes, ICommute } from "src/types";

interface IProps {
  ride: ICommute;
  cargoTypes: ICargoTypes;
}

class CargoComponent extends React.PureComponent<IProps> {
  public render() {
    const { ride, cargoTypes } = this.props;
    const cargoTypeName = getCargoTypeNameById(cargoTypes, ride.cargoTypeId);
    return (
      <View style={styles.container}>
        <RideProperty label={I18n.t("ride|cargo|cargoType")}>
          <Text style={styles.property}>{cargoTypeName}</Text>
        </RideProperty>
        <View style={styles.propertiesRawContainer}>
          <RideProperty label={I18n.t("ride|cargo|weight")}>
            <Text style={styles.property}>{ride.weight}</Text>
          </RideProperty>
          <RideProperty label={I18n.t("ride|cargo|size")}>
            <Text style={styles.property}>{ride.size}</Text>
          </RideProperty>
          <RideProperty label={I18n.t("ride|cargo|quantity")}>
            <Text style={styles.property}>{ride.quantity}</Text>
          </RideProperty>
          <RideProperty label={I18n.t("ride|cargo|dangerous")}>
            <Text style={styles.property}>{ride.dangerousText}</Text>
          </RideProperty>
        </View>
        <RideProperty label={I18n.t("ride|cargo|notes")}>
          <Text style={styles.property}>{ride.remarks}</Text>
        </RideProperty>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    marginVertical: size.m,
    justifyContent: "space-around",
    padding: size.m,
    borderTopWidth: borderWidth,
    borderTopColor: colors.secondaryText,
  },

  propertiesRawContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: size.m,
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  property: {
    ...fonts.style.BodySmall,
    color: colors.defaultText,
    marginTop: size.m,
  },
});

export default CargoComponent;
