import * as React from "react";
import { StatusBar, StyleSheet } from "react-native";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import colors from "src/Themes/colors";

interface IState {}

class RootWrapper extends React.PureComponent<{}, IState> {
  public render() {
    return (
      <SafeAreaProvider>
        <StatusBar barStyle="light-content" backgroundColor={colors.disable} />
        <SafeAreaView style={styles.container}>{this.props.children}</SafeAreaView>
      </SafeAreaProvider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: colors.disable,
  },
});

export default RootWrapper;
