import { AnyAction, combineReducers, Reducer } from "redux";
import { IReduxStoreState, ReduxStore } from "src/types";
import { ActionTypes, CollectionReducer, INITIAL_STATE, ObjectIdsReducer, storeStateTypes } from "./reducers";

export interface IReducer extends Reducer<ReduxStore> {}

const appReducer = combineReducers({
  rides: CollectionReducer(storeStateTypes.rides) as Reducer<IReduxStoreState["rides"]>,
  commutesObjects: ObjectIdsReducer(storeStateTypes.commutesObjects) as Reducer<IReduxStoreState["commutesObjects"]>,
  cargoTypes: CollectionReducer(storeStateTypes.cargoTypes) as Reducer<IReduxStoreState["cargoTypes"]>,
  companies: ObjectIdsReducer(storeStateTypes.companies) as Reducer<IReduxStoreState["companies"]>,
  commutesWithActualVehiclesRoutes: ObjectIdsReducer(storeStateTypes.commutesWithActualVehiclesRoutes) as Reducer<
    IReduxStoreState["commutesWithActualVehiclesRoutes"]
  >,
  user: CollectionReducer(storeStateTypes.user) as Reducer<IReduxStoreState["user"]>,
  users: ObjectIdsReducer(storeStateTypes.users) as Reducer<IReduxStoreState["users"]>,
  vehicles: ObjectIdsReducer(storeStateTypes.vehicles) as Reducer<IReduxStoreState["vehicles"]>,
});

export const rootReducer = (state: IReduxStoreState | undefined, action: AnyAction) => {
  if (action.type === ActionTypes.LOGOUT) {
    return appReducer(INITIAL_STATE, action);
  }
  return appReducer(state, action);
};
