import { currentLocale } from "i18n-js";
import * as _ from "lodash";
import { getDirections } from "src/Helpers/mapHelpers";
import I18n from "src/I18n";
import { locale } from "src/I18n/I18n";
import moment from "src/moment";
import {
  ApiVersionData,
  Coordinates,
  DriverDetails,
  ICargoTypes,
  ICommute,
  ICommuteTransformedState,
  ICommuteWithActualVehiclesRoutes,
  ICompany,
  IGetCargoTypesResponse,
  IGetCommutesApiResponse,
  IRawCommuteData,
  IRawCommuteDataWithActualVehiclesRoutes,
  IRawCompanyApiResponse,
  IRawNestedUserApiResponse,
  IRideDirection,
  ITransformedUser,
  LocaleToAddressMap,
  Phone,
  RawDirectionsApiResponse,
  RawPointApiResponse,
} from "src/types";

export const transformPartialCommute = ({
  _id,
  type,
  startDatetime,
  endDatetime,
  contract,
  vehiclesIds,
  roadDistance,
  companyId,
  originName,
  destinationName,
  originAddress,
  destinationAddress,
  originPoint,
  destinationPoint,
  durationInTraffic,
  directions,
  remarks,
  isEtaOk,
  matchesCount,
  dangerous,
  cmrDocumentPath,
  load,
  quantity,
  deliveryStatusHistory,
  deliveryStatus,
  cargoTypeId,
  size,
  carriersReservationsAndObservations,
}: IRawCommuteData): Partial<ICommute> => ({
  _id,
  type,
  vehiclesIds,
  startDatetime,
  endDatetime,
  remarks,
  etaDateTime: moment(startDatetime)
    .add(durationInTraffic, "seconds")
    .toDate(),
  contract,
  roadDistance,
  roadDistanceInKm: transformMetresToKilometers(roadDistance),
  netPrice: transformNetPrice(roadDistance),
  companyId,
  isEtaOk,
  cmrDocumentPath,
  durationInTraffic,
  directions: transformDirections(directions),
  originAddress: extractCityName(originAddress, originName),
  originName: originName,
  destinationAddress: extractCityName(destinationAddress, destinationName),
  destinationName: destinationName,
  destinationZipcode: extractPostcode(destinationAddress),
  originZipcode: extractPostcode(originAddress),
  originPoint: transformPoint(originPoint),
  rideTime: transformRideTime(durationInTraffic),
  destinationPoint: transformPoint(destinationPoint),
  matchesCount,
  dangerousText: transformDangerousToText(dangerous),
  weight: transformLoad(load),
  quantity,
  deliveryStatusHistory,
  deliveryStatus: deliveryStatus || undefined,
  cargoTypeId,
  size: `${size} ${I18n.t("currentRides|text|LDM")}` || I18n.t("ride|cargo|noData"),
  carriersReservationsAndObservations,
});

export const transformCommute = ({
  _id,
  type,
  startDatetime,
  endDatetime,
  contract,
  vehiclesIds,
  roadDistance,
  companyId,
  originName,
  destinationName,
  originAddress,
  destinationAddress,
  originPoint,
  destinationPoint,
  durationInTraffic,
  directions,
  remarks,
  isEtaOk,
  matchesCount,
  dangerous,
  cmrDocumentPath,
  load,
  quantity,
  deliveryStatusHistory,
  deliveryStatus,
  cargoTypeId,
  size,
  customerRole,
  userId,
  driversIds,
  contractorCompanyId,
}: IRawCommuteData): ICommute => ({
  _id,
  type,
  vehiclesIds,
  startDatetime,
  endDatetime,
  remarks,
  etaDateTime: moment(startDatetime)
    .add(durationInTraffic, "seconds")
    .toDate(),
  contract,
  roadDistance,
  roadDistanceInKm: transformMetresToKilometers(roadDistance),
  netPrice: transformNetPrice(roadDistance),
  companyId,
  isEtaOk,
  cmrDocumentPath,
  durationInTraffic,
  directions: transformDirections(directions),
  originAddress: extractCityName(originAddress, originName),
  originName: originName,
  destinationAddress: extractCityName(destinationAddress, destinationName),
  destinationName: destinationName,
  destinationZipcode: extractPostcode(destinationAddress),
  originZipcode: extractPostcode(originAddress),
  originPoint: transformPoint(originPoint),
  rideTime: transformRideTime(durationInTraffic),
  destinationPoint: transformPoint(destinationPoint),
  matchesCount,
  dangerousText: transformDangerousToText(dangerous),
  weight: transformLoad(load),
  quantity: quantity || I18n.t("ride|cargo|noData"),
  deliveryStatusHistory,
  deliveryStatus: deliveryStatus || undefined,
  cargoTypeId,
  size: `${size} ${I18n.t("currentRides|text|LDM")}` || I18n.t("ride|cargo|noData"),
  customerRole,
  userId,
  driversIds,
  contractorCompanyId,
});

const transformLoad = (load?: number) => {
  if (!load) {
    return I18n.t("ride|cargo|noData");
  }
  if (load < 1000) {
    return `${load} ${I18n.t("currentRides|text|kg")}`;
  }
  return `${load / 1000} ${I18n.t("currentRides|text|t")}`;
};

export const transformDangerousToText = (dangerous: boolean) => {
  if (dangerous) {
    return I18n.t("ride|cargo|yes");
  }
  return I18n.t("ride|cargo|no");
};

export const transformCommuteWithActualVehiclesRoutes = (
  data: IRawCommuteDataWithActualVehiclesRoutes,
): ICommuteWithActualVehiclesRoutes => ({
  ...transformCommute(data),
  actualVehiclesRoutes: getActualVehiclesRoutes(data),
});

const getActualVehiclesRoutes = (data: IRawCommuteDataWithActualVehiclesRoutes) => {
  if (!data.actualVehiclesRoutes) {
    return null;
  }
  if (!data.actualVehiclesRoutes.length) {
    return null;
  }
  const routesWithActualVehiclesRoutes = data.actualVehiclesRoutes.filter(
    (routes) => Boolean(routes.polyline) === true,
  );
  if (!routesWithActualVehiclesRoutes.length) {
    return null;
  }
  return routesWithActualVehiclesRoutes;
};

type Locales = {
  [key: string]: string;
};

export const transformCommutes = (raw: IGetCommutesApiResponse): ICommuteTransformedState => {
  return {
    commutes: raw.data ? raw.data.map(transformCommute) : [],
    total: raw.total,
    length: raw.length,
    currentPage: raw.currentPage,
    maxPages: raw.maxPages,
  };
};

export const transformCompany = (raw: IRawCompanyApiResponse): ICompany => {
  const { name, email, phoneNumbers, _id, logo } = raw.data;
  const firstPhoneNumber = phoneNumbers[0];
  return { name, email, firstPhoneNumber, _id, logoId: logo ? logo.public_id : undefined };
};

const getByLocales = (locales: Locales): string => {
  const deviceLng = locale;
  return locales[deviceLng] ? locales[deviceLng] : locales.en;
};

export const transformCargoTypeApiResponse = (raw: IGetCargoTypesResponse): ICargoTypes => {
  return raw.data.map((v) => {
    return {
      _id: v._id,
      name: getByLocales(v.locales),
    };
  });
};

const transformDriverDetailsToLicenseNumber = (driverDetails: DriverDetails) => {
  if (!driverDetails) {
    return I18n.t("ride|cargo|noData");
  }

  return driverDetails.license.number;
};

export const transformUser = ({
  _id,
  email,
  firstName,
  lastName,
  fullName,
  phoneNumbers,
  job,
  roles,
  photo,
  data,
  companyId,
  agreementsToUpdate,
  agreementVersions,
  driverDetails,
  hasPassword,
}: IRawNestedUserApiResponse): ITransformedUser => ({
  _id,
  email,
  firstName,
  lastName,
  fullName: fullName || I18n.t("ride|cargo|noData"),
  phoneNumbers,
  firstPhoneNumber: getFirstPhoneNumber(phoneNumbers),
  job,
  roles,
  photoId: getPhotoId(photo),
  photoUrl: getPhotoUrl(photo),
  error: false,
  errorMessage: data ? data.message : undefined,
  companyId,
  agreementsToUpdate,
  agreementVersions: agreementVersions ? agreementVersions : {},
  licenseNumber: transformDriverDetailsToLicenseNumber(driverDetails),
  hasPassword,
});

const getPhotoId = (photo: IRawNestedUserApiResponse["photo"]) => {
  if (!photo) {
    return;
  }
  return photo.public_id;
};

const getPhotoUrl = (photo: IRawNestedUserApiResponse["photo"]) => {
  if (!photo) {
    return;
  }
  return photo.url;
};

const getFirstPhoneNumber = (phoneNumbers: Phone[]) => {
  if (!phoneNumbers || !phoneNumbers[0]) {
    return;
  }
  return phoneNumbers[0].number;
};
const transformMetresToKilometers = (metres: number): number => Math.floor(metres / 1000);
const transformNetPrice = (metres: number): number => Math.floor((metres / 1000) * 1.12 + 23);
const transformDirections = (rawDirections: RawDirectionsApiResponse): IRideDirection | undefined => {
  const coords = rawDirections.routes[0].overview_polyline.points;

  return getDirections(coords);
};

const extractCityName = (address: LocaleToAddressMap, rawUserInput: string): string =>
  (_.get(address, `${currentLocale}.city`) || _.get(address, "en.city") || rawUserInput) as string;

const extractPostcode = (address: LocaleToAddressMap): string => _.get(address, "en.postcode") as string;

const transformPoint = (rawPoint: RawPointApiResponse): Coordinates => {
  return {
    latitude: rawPoint.coordinates[1],
    longitude: rawPoint.coordinates[0],
  };
};

export const transformGetApiVersionResponse = (res: ApiVersionData) => {
  return res.data.version.charAt(0);
};

const transformRideTime = (rideTime: number): Date => moment.utc((rideTime || 0) * 1000).toDate();
