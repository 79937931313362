import * as React from "react";
import { StyleSheet, Text } from "react-native";
import I18n from "src/I18n";
import StoreLoader, { IResponse, loadIndicatorsTypes } from "src/StoreLoader";
import colors from "src/Themes/colors";
import fonts from "src/Themes/fonts";
import { IVehicle } from "src/types";

interface IProps {
  vehicleId: string;
  rideId: string;
}

interface IState {
  destinationName: string;
}

class VehiclePlateLoader extends React.PureComponent<IProps, IState> {
  public render() {
    return (
      <StoreLoader
        type={loadIndicatorsTypes.paragraphPlaceholder}
        lines={1}
        stateName="vehicles"
        elementId={this.props.vehicleId}
        params={{ authorizeByCommute: this.props.rideId }}
        renderData={this.renderVehicle}
      />
    );
  }

  private renderVehicle = (vehicle: IResponse) => {
    const vehicleData = vehicle.data as IVehicle;
    if (!vehicleData.licensePlate) {
      return <Text style={styles.property}>{I18n.t("ride|cargo|noData")}</Text>;
    }
    return <Text style={styles.property}>{`# ${vehicleData.licensePlate}`}</Text>;
  };
}

const styles = StyleSheet.create({
  property: {
    ...fonts.style.BodySmall,
    color: colors.defaultText,
    lineHeight: 30,
  },
});

export default VehiclePlateLoader;
