import * as React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import reactNativePhoneCall from "react-native-phone-call";
import { Avatar, RideInfo, RideProperty } from "react-native-web-uikit";
import I18n from "src/I18n";
import PhoneIcon from "src/Images/PhoneIcon";
import StoreLoader, { IResponse } from "src/StoreLoader";
import colors from "src/Themes/colors";
import fonts from "src/Themes/fonts";
import { borderWidth, isWeb, size } from "src/Themes/variables";
import { IUser } from "src/types";

interface IProps {
  userId: string;
}

class ManagerComponent extends React.PureComponent<IProps> {
  public managerData: IResponse["data"] | undefined;
  public render() {
    return (
      <StoreLoader
        lines={1}
        stateName="users"
        elementId={this.props.userId}
        renderData={this.renderCompany}
        loadIndicatorStyles={styles.container}
      />
    );
  }

  public renderCompany = (manager: IResponse) => {
    this.managerData = manager.data as IUser;
    return (
      <RideInfo style={styles.container}>
        <RideProperty label={I18n.t("company|label|manager")}>
          <Avatar
            size="medium"
            imageId={this.managerData.photoId}
            source={{ uri: this.managerData.photoUrl }}
            name={this.managerData.lastName}
            accessibilityLabel={I18n.t("header|label|managerAvatar")}
          />

          <Text style={styles.managerName}>{this.managerData.fullName}</Text>
        </RideProperty>
        {this.renderPhone()}
      </RideInfo>
    );
  };

  private renderPhone = () => {
    const managerData = this.managerData as IUser;
    if (!managerData.firstPhoneNumber) {
      return null;
    }
    if (isWeb) {
      return (
        <RideProperty label={I18n.t("company|label|phone")}>
          <Text>{managerData.firstPhoneNumber}</Text>
        </RideProperty>
      );
    }
    return (
      <TouchableOpacity onPress={this.callToManager}>
        <PhoneIcon color={colors.defaultText} />
      </TouchableOpacity>
    );
  };

  private callToManager = () => {
    const managerData = this.managerData as IUser;
    const phoneNumber = managerData.firstPhoneNumber as string;
    reactNativePhoneCall({ number: phoneNumber });
  };
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "space-between",
    borderBottomWidth: borderWidth,
    borderBottomColor: colors.secondaryText,
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: size.l,
  },
  avatarContainer: {
    flexDirection: "row",
    marginRight: size.l,
    flex: 1,
    alignItems: "center",
  },
  label: {
    ...fonts.style.BodySmall,
    color: colors.subtitle,
  },
  managerName: {
    ...fonts.style.SubTitle,
    color: colors.defaultText,
  },
});

export default ManagerComponent;
