import { applyMiddleware, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import reduxThunk from "redux-thunk";
import { createEnhancer, reduxPersistConfig, setReduxStore } from "src/Config";
import { ReduxStore } from "src/types";
import { rootReducer } from "./rootReducer";

export const createAppStore = () => {
  const middleware = [];
  const enhancers = [];

  middleware.push(reduxThunk);

  const persistedReducer = persistReducer(reduxPersistConfig, rootReducer);

  enhancers.push(applyMiddleware(...middleware));

  let store: ReduxStore;

  if (__DEV__) {
    if (createEnhancer) {
      enhancers.push(createEnhancer(true));
    }
    store = createStore(persistedReducer, compose(...enhancers));
    setReduxStore(store);
  } else {
    store = createStore(persistedReducer);
  }
  const persistor = persistStore(store);
  return { store, persistor };
};
