import * as React from "react";
import { ActivityIndicator } from "react-native";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import I18n from "src/I18n/I18n";
import { persistor, store } from "src/Redux";
import RootContainer from "src/RootContainer";
import colors from "src/Themes/colors";

I18n();

export default class App extends React.PureComponent {
  public render() {
    return (
      <Provider store={store}>
        <PersistGate loading={<ActivityIndicator size="large" color={colors.themeColor} />} persistor={persistor}>
          <RootContainer />
        </PersistGate>
      </Provider>
    );
  }
}
