import * as React from "react";
import { BackButton, Route } from "react-router-native";
import LoginForm from "src/Features/LoginForm";
import PasswordForm from "src/Features/PasswordForm";
import UnAuthWrapper from "src/Wrappers/UnAuthWrapper";
import AppRouter from "./AppRouter";

export enum UnAuthRouteNames {
  Login = "",
  Password = "password",
}

export const UnAuthNavigation = (props: {
  resetPassword: {
    isOpen: boolean;
    id?: string;
    token?: string;
  };
}) => {
  return (
    <AppRouter>
      <UnAuthWrapper>
        <BackButton>
          <Route exact path={`/${UnAuthRouteNames.Login}`} component={LoginForm} />
          <Route exact path={`/${UnAuthRouteNames.Password}`} component={PasswordForm} />
        </BackButton>
      </UnAuthWrapper>
    </AppRouter>
  );
};
