import * as React from "react";
import { BackButton, Route } from "react-router-native";
import Header from "src/Features/Common/Header";
import DeliveryStatusChanger from "src/Features/DeliveryStatusChanger";
import MainPage from "src/Features/MainPage";
import Map from "src/Features/Map";
import ResetPasswordForm from "src/Features/PasswordForm/ResetPasswordForm";
import AuthWrapper from "src/Wrappers/AuthWrapper";
import AppRouter from "./AppRouter";

export enum AuthRouteNames {
  Main = "",
  Map = "map",
  DeliveryStatus = "deliveryStatus",
  ResetPassword = "resetPassword",
}

export const getMainRoute = () => `/${AuthRouteNames.Main}`;
export const getResetPasswordRoute = () => `/${AuthRouteNames.ResetPassword}`;
export const getDeliveryStatusRoute = (rideId: string) => `/${AuthRouteNames.DeliveryStatus}/${rideId}`;
export const getMapRoute = (rideId: string, rideIndex: string | number) =>
  `/${AuthRouteNames.Map}/${rideId}/${rideIndex}`;

const authRoutes = {
  main: getMainRoute(),
  deliveryStatus: getDeliveryStatusRoute(":rideId"),
  map: getMapRoute(":rideId", ":rideIndex"),
};

const resetPasswordRoute = getResetPasswordRoute();

type AuthRoutesKeys = keyof typeof authRoutes;
const authRoutesKeys = Object.keys(authRoutes) as AuthRoutesKeys[];

export const AuthNavigation = () => {
  return (
    <AppRouter>
      <BackButton>
        <AuthWrapper>
          <Route exact path={authRoutesKeys.map((key: AuthRoutesKeys) => authRoutes[key])} component={Header} />
          <Route path={authRoutes.main} component={MainPage} />
          <Route exact path={authRoutes.map} component={Map} />
          <Route exact path={authRoutes.deliveryStatus} component={DeliveryStatusChanger} />
          <Route exact path={resetPasswordRoute} component={ResetPasswordForm} />
        </AuthWrapper>
      </BackButton>
    </AppRouter>
  );
};
