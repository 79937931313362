import * as React from "react";
import ChangeStatusButton from "src/Features/RidesList/Components/ChangeStatusButton";
import { isCargoReceived, isCargoSent } from "src/Helpers/ridesHelpers";
import I18n from "src/I18n";
import StoreLoader, { IResponse } from "src/StoreLoader";
import { ICommute } from "src/types";

interface IProps {
  ride: ICommute;
}

class ChangeStatusButtonContainer extends React.PureComponent<IProps> {
  public render() {
    return (
      <StoreLoader
        lines={1}
        stateName="commutesObjects"
        elementId={this.props.ride._id}
        renderData={this.renderRides}
        updateOnChangeState={["rides"]}
      />
    );
  }

  private renderRides = (commute: IResponse) => {
    const commuteData = commute.data as ICommute;
    if (commuteData.customerRole === "RECEIVER") {
      return this.renderReceiveCargoButton();
    }
    return this.renderSendCargoButton();
  };

  private renderSendCargoButton = () => {
    const { deliveryStatus, deliveryStatusHistory } = this.props.ride;
    if (isCargoSent(deliveryStatus, deliveryStatusHistory)) {
      return null;
    }
    return (
      <ChangeStatusButton
        label={I18n.t("ride|button|iveSentTheCargo").toLocaleUpperCase()}
        customerRole="SENDER"
        ride={this.props.ride}
      />
    );
  };

  private renderReceiveCargoButton = () => {
    const { deliveryStatus } = this.props.ride;
    if (isCargoReceived(deliveryStatus)) {
      return null;
    }
    return (
      <ChangeStatusButton
        label={I18n.t("ride|button|iveReceivedTheCargo").toLocaleUpperCase()}
        customerRole="RECEIVER"
        ride={this.props.ride}
      />
    );
  };
}

export default ChangeStatusButtonContainer;
