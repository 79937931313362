import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-native";
import { getDirections } from "src/Helpers/mapHelpers";
import StoreLoader, { IResponse, loadIndicatorsTypes } from "src/StoreLoader";
import { ICommuteWithActualVehiclesRoutes, Region } from "src/types";
import RideCardMap from "./Components/RideCardMap";

interface IProps extends RouteComponentProps<{ rideId: string }> {
  rideId: string;
}

type DecodedPolyline = {
  vehicleId: string;
  coords: {
    latitude: number;
    longitude: number;
  }[];
  initialRegion: Region;
}[];

class RideCardContainer extends React.PureComponent<IProps> {
  public commute: IResponse | undefined;
  public render() {
    return (
      <StoreLoader
        type={loadIndicatorsTypes.mapPlaceholder}
        stateName="commutesObjects"
        requestName="getCommuteWithActualVehiclesRoutes"
        elementId={this.props.rideId}
        renderData={this.renderCommute}
        refreshEvery={15000}
        maxAge={0}
      />
    );
  }

  private renderCommute = (commute: IResponse) => {
    const commuteData = commute.data as ICommuteWithActualVehiclesRoutes;
    const actualVehiclesRoutes = this.getTruckRoute(commuteData);
    return <RideCardMap actualVehiclesRoutes={actualVehiclesRoutes} ride={commuteData} />;
  };

  private getTruckRoute = (commute: ICommuteWithActualVehiclesRoutes) => {
    if (!commute.actualVehiclesRoutes) {
      return;
    }
    const decodedPolyline = commute.actualVehiclesRoutes.map((route) => ({
      ...getDirections(route.polyline),
      vehicleId: route.vehicleId,
    })) as DecodedPolyline;
    return decodedPolyline;
  };
}

export default withRouter(RideCardContainer);
